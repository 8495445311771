export default {
  TEAM_PERSON_VIEW: 'TEAM_PERSON_VIEW',
  LIGHTBOX: 'LIGHTBOX',
  CONTACT: 'CONTACT',
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  PDF_VIEW: 'PDF_VIEW',
  MEDIA_MODAL: 'MEDIA_MODAL',
  LESSON_INFO_MODAL: 'LESSON_INFO_MODAL'
};
