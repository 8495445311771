import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import modalNames from '../modals/names';
import * as modalActions from '../../actions/modals';

import erasmusLogo from '../../assets/svg/Erasmus_logo.svg';
import acoucouLogo from '../../assets/svg/logo_acoucou_white.svg';
import aciLogo from '../../assets/svg/aci_full_negative.svg';

import fbIcon from '../../assets/svg/socialIcons/fb-icon.svg';
import instagramIcon from '../../assets/svg/socialIcons/instagram-icon.svg';
import twitterIcon from '../../assets/svg/socialIcons/twitter-icon.svg';

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="cta-section">
          <div className="cta-section-text sign">
            <p className="cta-section-element text-center">
              <FormattedMessage id="footer.cta.p1" />
            </p>
            <p className="cta-section-element text-center">
              <FormattedMessage id="footer.cta.p2" />
            </p>
          </div>

          <div
            className="btn btn-secondary-inverse btn-sm-lg"
            onClick={() => this.props.openModal(modalNames.CONTACT)}
          >
            <FormattedMessage id="footer.cta.contact" />
          </div>
        </div>

        <div className="container py-5 text-center text-md-left">
          <div className="row">
            <div
              className={`col-lg-3 col-md-3 offset-md-0 col-sm-6 offset-sm-3 col-8 offset-2 py-4`}
            >
              <img src={aciLogo} alt="ACE" className="img-fluid mx-auto logo" />
            </div>
            <div className="col-md py-4 footer-section">
              <div className="footer-section-element">
                <FormattedMessage id="footer.products.title" />
              </div>
              <a href="http://ace.acoucou.org" className="footer-section-element">
                <FormattedMessage id="footer.products.ace" />
              </a>
              <a
                href="http://arac-multibook.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-section-element"
              >
                <FormattedMessage id="footer.products.arac" />
              </a>
              <a
                href="https://aci.acoucou.org/"
                className="footer-section-element">
                <FormattedMessage id="footer.products.aci" />
              </a>
              <a
                href="http://scientificzone.acoucou.org"
                className="footer-section-element"
              >
                <FormattedMessage id="footer.products.scientific_zone" />
              </a>
              <div
                className="footer-section-element"
                onClick={() => this.props.openModal(modalNames.CONTACT)}
              >
                <FormattedMessage id="footer.products.contact" />
              </div>
            </div>
            <div className="col-md py-4 footer-section">
              <div className="footer-section-element">
                <FormattedMessage id="footer.about.title" />
              </div>
              <div
                className="footer-section-element"
                // onClick={toggleWipLesson}
              >
                <FormattedMessage id="footer.about.materials" />
              </div>
              <Link
                className="footer-section-element"
                to="/about"
                // onClick={toggleWip}
              >
                <FormattedMessage id="footer.about.aci" />
              </Link>
            </div>
            <div className="col-md py-4 footer-section">
              <div className="footer-section-element">
                <FormattedMessage id="footer.social.title" />
              </div>
              <a
                href={'https://twitter.com/Acoucou_org'}
                className="footer-section-element"
              >
                <FormattedMessage id="footer.social.twitter" />
              </a>
              <a
                href={'https://www.facebook.com/acoucou.org/'}
                className="footer-section-element"
              >
                <FormattedMessage id="footer.social.facebook" />
              </a>
              <a
                href={'https://www.instagram.com/acoucouacoustics/'}
                className="footer-section-element"
              >
                <FormattedMessage id="footer.social.instagram" />
              </a>
            </div>
            <div className="col-md py-4 footer-section">
              <div className="footer-section-element">
                <FormattedMessage id="footer.legal.title" />
              </div>
              <div className="footer-section-element">
                <FormattedMessage id="footer.legal.use" />
              </div>
            </div>
          </div>
          <div className="row py-2 footer__bottom">
            <div className="col-lg-5 copyright">
              <img
                src={erasmusLogo}
                alt="Erasmus"
                className="img-fluid footer__logo footer__logo--big"
              />
              <FormattedMessage id="footer.erasmus" />
            </div>
            <div className="col-lg-5 copyright copyright--right">
              <img src={acoucouLogo} alt="ACE" className="img-fluid footer__logo" />
              <span>
                <FormattedMessage id="footer.copyright" />
                {/* <span>{this.getCurrentYear()}.</span> */}
              </span>
            </div>
            <div className="col-lg-2 copyright copyright--right">
              <a
                className="social-bar__icon"
                href="https://www.facebook.com/acoucou.org/"
              >
                <img src={fbIcon} className="social-bar__icon" />
              </a>
              <a className="social-bar__icon" href="https://twitter.com/Acoucou_org">
                <img src={twitterIcon} className="social-bar__icon" />
              </a>
              <a
                className="social-bar__icon"
                href="https://www.instagram.com/acoucouacoustics/"
              >
                <img src={instagramIcon} className="social-bar__icon" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  openModal: propTypes.func
};

export default withRouter(
  connect(
    null,
    {
      openModal: modalActions.open
    }
  )(Footer)
);
