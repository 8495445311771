import * as React from 'react';
import { Modal } from 'reactstrap';

import ModalConnector from '../Generic/Modals/ModalConnector';
import names from './names';

import Loader from '../../components/Loader/Loader';

import { Document, Page } from 'react-pdf/dist/entry.webpack';

class PdfViewModal extends React.Component {
  state = {
    loadingProgress: 0,
    scale: 1
  };

  onPageLoadSuccess = () => {
    if (!this.pdfPage) {
      return {
        pdfHorizontalOverlap: 0,
        pdfVerticalOverlap: 0,
      };
    }

    const clientRect = this.pdfPage.getBoundingClientRect();
    const windowWidth = window.innerWidth;

    const scale = windowWidth/clientRect.width;

    if (scale <= 1.1) {
      this.setState({
        scale: scale * 0.9
      });
    }
  };

  onModalClose = (onClose) => {
    onClose();

    this.setState({
      scale: 1
    });

  };

  render() {
    const { loadingProgress, scale } = this.state;
    
    return (
      <ModalConnector name={names.PDF_VIEW}>
        {({ isOpen, data, onClose }) => {
          return (
            <Modal
              isOpen={isOpen}
              toggle={() => this.onModalClose(onClose)}
              centered
              contentClassName="modal-pdf-view"
              className="modal-pdf-view-wrapper"
              backdropClassName="modal-pdf-view-backdrop"
            >
              <div className="d-flex justify-content-end">
                <span
                  onClick={() => this.onModalClose(onClose)}
                  className="icon-close modal-pdf-view-close"
                />
              </div>
              <Document
                file={data.file}
                className="pdf-content"
                onLoadProgress={({ loaded, total }) =>
                  this.setState({ loadingProgress: Math.floor((loaded / total) * 100) })
                }
                loading={<Loader progress={loadingProgress} />}
              >
                <Page
                  inputRef={ref => (this.pdfPage = ref)}
                  onLoadSuccess={this.onPageLoadSuccess}
                  pageNumber={1}
                  scale={scale}
                  loading={<Loader progress={loadingProgress} />}
                />
              </Document>
            </Modal>
          );
        }}
      </ModalConnector>
    );
  }
}

export default PdfViewModal;
