import React from 'react';

import classnames from 'classnames';

import PropTypes from 'prop-types';

import Sound from 'react-sound';

import './AudioPlayer.scss';

class AudioPlayer extends React.Component {

  state = {
    position: 0,
    duration: 0
  };

  onPlaying = (e) => {
    this.setState({
      position: e.position,
      duration: e.duration
    });
  };

  onPlayClick = () => {
    const {playStatus, onPauseClick, onPlayClick} = this.props;

    if(playStatus === 'PLAYING') {
      return onPauseClick();
    }

    return onPlayClick();
  };

  onStopClick = () => {
    const {onStopClick} = this.props;

    this.setState({
      position: 0,
    });

    onStopClick();
  };

  render() {
    const {position, duration} = this.state;

    if(!this.props.soundUrl) {
      return null;
    }

    const width = position / duration * 100;

    return (
     <div className="audio-player">
      <Sound
        url={this.props.soundUrl}
        playStatus={Sound.status[this.props.playStatus]}
        onPlaying={(e) => this.onPlaying(e)}
      />
       <div
        className={classnames('play-button', {
          'paused': this.props.playStatus === 'PAUSED',
          'stopped': this.props.playStatus === 'STOPPED'
        })}
        onClick={this.onPlayClick}
       >

       </div>
       <div className="audio-progress">
        <div className="audio-progress-bar">
          <div
            className="audio-progress-bar-fill"
            style={{
              width: `${width}%`
            }}
          >
          </div>
        </div>
       </div>
       <div
        className={classnames('stop-button', {
          'active': this.props.playStatus !== 'STOPPED'
        })}
        onClick={this.onStopClick}
       >

       </div>
       
       
     </div>
    );
  }
}

AudioPlayer.propTypes = {
  soundUrl: PropTypes.string,
  playStatus: PropTypes.string,
  onStopClick: PropTypes.func,
  onPauseClick: PropTypes.func,
  onPlayClick: PropTypes.func
};



export default AudioPlayer;
