import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import modalActions from '../../actions/modals';
import modalNames from '../../components/modals/names';

import sound from '../../assets/svg/sound.svg';
import headphones from '../../assets/svg/headphones.svg';
import SideMenu from '../SideMenu/SideMenu';
import audioIco from '../../assets/svg/audio_ico.svg';
import AudioPlayer from '../AudioPlayer/AudioPlayer';

import { lessonsMediaConfig } from './LessonsMediaConfig';
import { lessonsSoundConfig } from './LessonsSoundConfig';
import Sound from 'react-sound';

import PdfLoader from '../PdfLoader/PdfLoader';

import './LessonMedia.scss';

const lessonsSideMenuConfig = {
  ico: audioIco,
  title: 'sidemenu.audio.title',
  menu: [{
      groupName: 'sidemenu.audio.group1',
      data: [{
          text: 'sidemenu.audio.group1.item1',
          link: '/materials/media/9/1'
        }, {
          text: 'sidemenu.audio.group1.item2',
          link: '/materials/media/9/2'
        }, {
          text: 'sidemenu.audio.group1.item3',
          link: '/materials/media/9/3'
        }, {
          text: 'sidemenu.audio.group1.item4',
          link: '/materials/media/9/4'
        }]
      }, {
        groupName: 'sidemenu.audio.group2',
        data: [{
            text: 'sidemenu.audio.group2.item1',
            link: '/materials/media/9/5'
          }, {
            text: 'sidemenu.audio.group2.item2',
            link: '/materials/media/9/6'
          }, {
            text: 'sidemenu.audio.group2.item3',
            link: '/materials/media/9/7'
          }, {
            text: 'sidemenu.audio.group2.item4',
            link: '/materials/media/9/8'
          }]
      }]
};

class LessonMedia extends React.Component {
  state = {
    soundUrl: '',
    status: 'STOPPED'
  };

  componentDidMount() {
    const { audioPage } = this.props.match.params;

    if(audioPage !== '1') {
      return;
    }

    return this.props.openModal(modalNames.MEDIA_MODAL,
      {
        title: 'modal.media.audio.title',
        text: 'modal.media.audio.text',
        images: [headphones, sound]
      });
  }

  componentDidUpdate(prevProps) {
    if(this.props.match.url !== prevProps.match.url) {
      this.setState({
        status: 'STOPPED',
        soundUrl: ''
      });
    }
  }

  onLinkClick = (link) => {
    const soundName = link.href.replace('http://', '').slice(0, -1);

    this.setState({
      status: 'PLAYING',
      soundUrl: lessonsSoundConfig[soundName]
    });
  };

  onStatusClick = (status) => {
    this.setState({
      status: status,
    });
  };

  render() {
    const { language } = this.props;
    const { audioPage } = this.props.match.params;
    const {status, soundUrl} = this.state;


    return (
      <div className="lesson-media lesson-media-audio">
        <SideMenu
          ico={lessonsSideMenuConfig.ico}
          title={lessonsSideMenuConfig.title}
          menu={lessonsSideMenuConfig.menu}
        />
        <div className="media-content">
          <PdfLoader
            file={lessonsMediaConfig[language][parseInt(audioPage) - 1]}
            onLinkClick={this.onLinkClick}
          />
          <AudioPlayer
            soundUrl={soundUrl}
            playStatus={Sound.status[status]}
            onStopClick={() => this.onStatusClick('STOPPED')}
            onPauseClick={() => this.onStatusClick('PAUSED')}
            onPlayClick={() => this.onStatusClick('PLAYING')}
          />
        </div>
      </div>
    );
  }
}

LessonMedia.propTypes = {
  match: PropTypes.object,
  language: PropTypes.string,
  openModal: PropTypes.func,
};

export default connect(
  (state) => ({
    language: state.language
  }),
  ({
    openModal: modalActions.open,
  })
)(LessonMedia);
