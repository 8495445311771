import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Slider from 'react-slick';

import SocialBar from '../../components/SocialBar/SocialBar';
import About from './About';
import Gallery from './Gallery';
import Logos from './Logos';
import Team from './Team';

// import LightBoxModal from '../../components/modals/LightBox/LightBox';

import {gallery} from './config';

class AboutPage extends React.Component {
    constructor(props) {
        super(props);

        this.breakpoint = 991;

        this.state = {
            windowWidth: 0,
        };
    }

    updateWindowWidth = () => {
      this.setState({ windowWidth: window.innerWidth });
    };

    componentDidMount() {
      this.updateWindowWidth();
      window.addEventListener('resize', this.updateWindowWidth);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowWidth);
    }

    // getImageCaption(image) {
    //   const id = galleryImages.indexOf(image);

    //   if (id === 0 || id === 2 || id === 5 || id === 6) {
    //       return 'ACE Simulator Measurments. KFB Headquarter.';
    //   }

    //   if (id === 1 || id === 3 || id === 4) {
    //       return 'ACE Workshops in Wrocław. KFB Headquarter.';
    //   }
    // }

    renderSlider() {
      const settings = {
        arrows: true,
        autoplay: true,
        dots: true,
        draggable: false,
        fade: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        swipe: true,
        swipeToSlide: true,
        touchMove: true,
      };

      return (
        <div className="container mb-5">
          <div className="about-us-slider pb-5 px-3">
            <Slider {...settings}>
              {gallery.map((image, index) => {
                return (
                  <div key={index}>
                    <img src={image} />
                    {/* <p className='lightbox__text lightbox__text--slider pt-3 text-center'>
                        {this.getImageCaption(image)}
                    </p> */}
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      );
    }

    render() {
        const isSmallScreen = this.state.windowWidth < this.breakpoint;

        return (
            <div className="page-about">
                <div className="about-team-wrapper">
                  <div className="col-12 mt-5 overflow-hidden py-5">
                    <div className="quote pt-5">
                      <span className="content">
                          <FormattedMessage id="page.about.hero.quote.content" />
                      </span>

                      <span className="author">
                          <span>-</span>
                          <FormattedMessage id="page.about.hero.quote.author" />
                      </span>
                    </div>
                  </div>
                    <SocialBar />
                    {!isSmallScreen ? <Gallery /> : this.renderSlider()}
                    <About />
                    <Logos />
                    <Team />
                </div>
                {/* {!isSmallScreen && <LightBoxModal />} */}
            </div>
        );
    }
}

export default AboutPage;
