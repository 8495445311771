import React from 'react';
import { Document, Page } from 'react-pdf/dist/entry.webpack';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import Loader from '../../components/Loader/Loader';

class PdfLoader extends React.Component {
  state = {
    numPages: 0,
    scale: 60,
    fitScale: 60,
    loadingProgress: 0,
  };

  componentDidMount() {
    window.addEventListener('resize', this.onWindowResize);
    this.fitToWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    this.onPageLoadSuccess();
    this.calculateFitScale();
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  calculateFitScale = () => {
    const pdfWrapperRect = this.pdfWrapper.getBoundingClientRect();
    const scale = Math.round((pdfWrapperRect.height-70)/10);

    this.setState({ fitScale:  scale});
    return scale;
  };

  fitToWindow = () => {
    this.setState({ scale: this.calculateFitScale()}, () => {
      this.setOverlap();
    });

    // faking drag to center
    // https://github.com/mzabriskie/react-draggable/issues/363
    const clickEvent = document.createEvent ('MouseEvents');
    clickEvent.initEvent ('mousedown', true, true);
    this.pdfPage && this.pdfPage.dispatchEvent(clickEvent);
  };

  changeScale = (action) => {
    if (this.state.scale <= 20 && action === 'decrease') {
      return;
    }

    const { scale } = this.state;

    if (action === 'decrease') {
      return this.setState({ scale: this.state.scale % 10 === 0 ? scale-10 : Math.floor(scale/10)*10 }, () => {
        this.setOverlap();
      });
    }

    this.setState({ scale: this.state.scale % 10 === 0 ? scale+10 : Math.ceil(scale/10)*10 }, () => {
      this.setOverlap();
    });
  };

  setOverlap = () => {
    const { pdfHorizontalOverlap, pdfVerticalOverlap } = this.getPageDimensions();
    this.setState({ pdfHorizontalOverlap, pdfVerticalOverlap });
  };

  formatScale() {
    return `${Math.floor(this.state.scale)}%`;
  }

  getPageDimensions = () => {
    if (!this.pdfPage) {
      return {
        pdfHorizontalOverlap: 0,
        pdfVerticalOverlap: 0,
      };
    }

    const clientRect = this.pdfPage.getBoundingClientRect();
    const pdfWrapperRect = this.pdfWrapper.getBoundingClientRect();
    const pdfHorizontalOverlap = clientRect.width - pdfWrapperRect.width;
    const pdfVerticalOverlap = clientRect.height - pdfWrapperRect.height;

    return {
      pdfHorizontalOverlap: pdfHorizontalOverlap > 0 ? pdfHorizontalOverlap : 0,
      pdfVerticalOverlap: pdfVerticalOverlap > 0 ? pdfVerticalOverlap : 0,
    };
  };

  onPageLoadSuccess = () => {
    const { pdfHorizontalOverlap, pdfVerticalOverlap } = this.getPageDimensions();

    this.setState({
      pdfHorizontalOverlap,
      pdfVerticalOverlap,
    });
  };

  onGetAnnotationsSuccess = () => {
    const links = document.querySelectorAll('.react-pdf__Page a');
    const { onLinkClick } = this.props;

    for (const link of links) {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        onLinkClick(link);
      });
    }
  };

  renderZoomTools = () => {
    const { scale, fitScale } = this.state;
    const isFitted = fitScale === scale ? true : false;

    return (
      <div className="pdf-zoom-wrapper">
        {!isFitted &&
        <div className="pdf-zoom-content fit-to-screen-wrapper">
          <span className="fit-to-screen-text" onClick={() => this.fitToWindow()}>Fit to screen</span>
        </div>}
        <div className="pdf-zoom-content pdf-amount-wrapper">
          <span className="pdf-zoom-icon" onClick={() => this.changeScale('decrease')}>-</span>
          <span className="pdf-zoom-amount">{this.formatScale()}</span>
          <span className="pdf-zoom-icon" onClick={() => this.changeScale('increase')}>+</span>
        </div>
      </div>
    );
  };

  render() {
    const { scale, pdfHorizontalOverlap, pdfVerticalOverlap, loadingProgress } = this.state;
    const { file, isZoom } = this.props;

    return (
      <div className="pdf-wrapper" ref={ref => this.pdfWrapper = ref}>
        {isZoom && this.renderZoomTools()}
        <Document
          file={file}
          onLoadSuccess={this.onDocumentLoadSuccess}
          className="pdf-content pdf-lesson"
          onLoadProgress={({ loaded, total }) => this.setState({ loadingProgress: Math.floor((loaded / total) * 100) })}
          loading={<Loader showText progress={loadingProgress} />}
        >
          <Draggable
            bounds={{
              top: -pdfVerticalOverlap / 2,
              left: -pdfHorizontalOverlap / 2,
              right: pdfHorizontalOverlap / 2,
              bottom: pdfVerticalOverlap / 2,
            }}
          >
            <div className="pdf-draggable">
              <Page
                inputRef={ref => this.pdfPage = ref}
                pageNumber={1}
                scale={scale/100}
                onLoadSuccess={this.onPageLoadSuccess}
                onGetAnnotationsSuccess={this.onGetAnnotationsSuccess}
                loading={<Loader showText progress={loadingProgress} />}
              />
            </div>
          </Draggable>
        </Document>
      </div>
    );
  }
}

PdfLoader.propTypes = {
  file: PropTypes.string,
  onLinkClick: PropTypes.func,
  isZoom: PropTypes.bool,
  language: PropTypes.string,
  openModal: PropTypes.func,
};

export default PdfLoader;
