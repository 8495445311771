import * as classnames from 'classnames';
import { Link } from 'react-router-dom';
import React from 'react';
import {withRouter} from 'react-router';
import { FormattedMessage } from 'react-intl';
import propTypes from 'prop-types';
import {connect} from 'react-redux';

import * as modalActions from '../../actions/modals';
import { logout } from '../../actions/authActions';
import ApiAuth from '../../services/ApiAuth';

import modalNames from '../modals/names';

import logoACI from '../../assets/svg/aci_short.svg';

const externalLinks = [
  {
    href: 'http://acoucou.org',
    id: 'menu.products',
  }
];

const menuLinks = [
  {
    id: 'menu.homepage',
    to: '/',
  },
  {
    id: 'menu.about',
    to: '/about',
  },
];

class TopBar extends React.Component {
  state = {
    mobileExpand: false,
  };

  handleToggleMobile = () => {
    return this.setState(prevState => ({
      mobileExpand: !prevState.mobileExpand,
    }));
  };

  logout = () => {
    this.props.logout();
  };

  render() {
    // const { mobileExpand } = this.state;

    const {location, loginData} = this.props;
    const isLogged = loginData && !!loginData.username;

    return (
      <nav
        id="nav-menu"
        className={classnames('navbar navbar-toggleable-xl fixed-top d-block navbar-light scrolled', {
          'extra-logo-animation': location.pathname === '/',
        })}
      >
        <div className="container hidden-sm-down">
          <Link className="navbar-brand" to="/">
            <img src={logoACI} alt="Logo" />
          </Link>
          <ul className="navbar-nav ml-auto">
            {externalLinks.map((item) => {
              return <li
                className="nav-item"
                key={item.id}
              >
                <a
                  className="nav-link"
                  href={item.href}
                >
                  <FormattedMessage id={item.id} />
                </a>
              </li>;
            })}
            {menuLinks.map((item) => {
              return <li
                className={classnames('nav-item', {
                    active: location.pathname === item.to,
                })}
                key={item.id}
              >
                <Link
                  className="nav-link" to={item.to}
                  onClick={() => {
                    // scroll to products on products element
                    if (item.to !== '/') {
                        return;
                    }
                  }}
                >
                  <FormattedMessage id={item.id} />
                </Link>
              </li>;
              })}
              <li
                key="menu.contact"
                className="nav-item"
                onClick={() => this.props.openModal(modalNames.CONTACT)}
              >
                <div className="nav-link">
                  <FormattedMessage id="menu.contact" />
                </div>
              </li>
              {
                isLogged &&
                <li
                  className="nav-item"
                  // onClick={() => browserHistory.push('/user/profile')}
                >
                  <div className="nav-link with-no-padding-right">
                    <span className="navbar__username">
                      <strong>{ApiAuth.getUserName(loginData.username)}</strong>&nbsp;|&nbsp;
                    </span>
                  </div>
                </li>
              }
              {
                !isLogged ? (
                  <li
                    className="nav-item"
                    onClick={() => this.props.openModal(modalNames.LOGIN)}
                  >
                    <div className="nav-link with-extra-content">
                      <FormattedMessage id="menu.login" />
                      <span className="icon-user extra-content" />
                    </div>
                  </li>
                ) : (
                  <li
                    className="nav-item"
                    onClick={this.logout}
                  >
                    <div className="nav-link with-no-padding-left with-extra-content">
                      <FormattedMessage id="menu.logout" />
                      <span className="icon-user extra-content" />
                    </div>
                  </li>
                )
              }
          </ul>
        </div>
        <div className="container d-block mx-auto hidden-md-up">
          <div className="row d-block">
            <div className={classnames('navbar-mobile', {
                expand: this.state.mobileExpand,
            })}>
              <div className="navbar-left">
                <span
                  className="icon-user icon-topbar"
                  onClick={() => {
                    if (!isLogged) {
                        this.handleToggleModal('login');
                    }
                  }}
                />
              </div>
              <div className="navbar-center">
                <img src={logoACI} alt="Logo" />
              </div>
              <div className="navbar-right" onClick={this.handleToggleMobile}>
                <span className="icon-topbar icon-menu" />
              </div>
            </div>

            <div className="navbar-mobile-overlay" onClick={this.handleToggleMobile} />
            <ul className="navbar-nav text-right" onClick={this.handleToggleMobile}>
              <li className="nav-item">
                  <span className="nav-link icon-topbar icon-arrowR" />
              </li>
              <li className="nav-item">
                  <Link
                      className="nav-link icon-topbar icon-signet text-primary" to="/" />
              </li>
              {menuLinks.map((item) => {
                return <li
                  className={classnames('nav-item', {
                  active: location.pathname === item.to,
                })}
                  key={item.id}
                >
                  <Link className="nav-link" to={item.to} onClick={() => {
                    // scroll to products on products element
                    if (item.to !== '/') {
                      return;
                    }
                  }}>
                    <FormattedMessage id={item.id} />
                  </Link>
                </li>;
              })}
              <li
                key="menu.contact"
                className="nav-item"
                onClick={() => this.props.openModal(modalNames.CONTACT)}
              >
                <div className="nav-link">
                  <FormattedMessage id="menu.contact" />
                </div>
              </li>
              {!isLogged ? (
                <li
                  key="menu.login"
                  className="nav-item"
                  onClick={() => this.props.openModal(modalNames.LOGIN)}
                >
                  <div className="nav-link">
                    <FormattedMessage id="menu.login" />
                  </div>
                </li>
                ) : (
                <li
                  className="nav-item"
                  onClick={this.logout}
                >
                  <div className="nav-link">
                    <FormattedMessage id="menu.logout" />
                  </div>
                </li>
                )
              }
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

TopBar.propTypes = {
  location: propTypes.object,
  openModal: propTypes.func.isRequired,
  logout: propTypes.func.isRequired,
  loginData: propTypes.object,
};

export default withRouter(
connect(
  (state) => ({
    loginData: state.login.data,
  }),
  ({
    openModal: modalActions.open,
    logout,
  })
)(TopBar));
