import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {Modal} from 'reactstrap';

import { login } from '../../actions/authActions';
import { open } from '../../actions/modals';

import ModalConnector from '../Generic/Modals/ModalConnector';

import names from './names';

import './Contact.scss';

class Login extends React.Component {
    state = {
      email: '',
      password: '',
      showValidation: false,
    };

    handleModalClose = (onClose) => {
      this.setState({
        email: '',
        password: '',
        showValidation: false,
      });
      onClose();
    };

    handleRegisterModalOpen = (onClose) => {
      this.handleModalClose(onClose);
      this.props.open(names.REGISTER);
    };

    handleResetPasswordModalOpen = (onClose) => {
      this.handleModalClose(onClose);
      this.props.open(names.RESET_PASSWORD);
    };

    handleEmailChange = (e) => {
      this.setState({
        email: e.target.value,
      });
    };

    handlePasswordChange = (e) => {
      this.setState({
          password: e.target.value,
      });
    };

    submitLogin = (e) => {
      e.preventDefault();

      const {email, password} = this.state;

      if (!email || !password) {
        return this.setState({
            showValidation: true,
        });
      }

      const data = {
        password: this.state.password,
        username: this.state.email,
      };

      this.props.login(data);
    };

    render() {
      const { intl, loginSuccess, loginError } = this.props;
      const {email, password, showValidation} = this.state;

      return (
        <ModalConnector name={names.LOGIN}>
          {
            ({isOpen, onClose}) => {
              return (
                <Modal
                  size="md"
                  isOpen={isOpen}
                  toggle={() => this.handleModalClose(onClose)}
                  contentClassName="modal-contact-content"
                  className="modal-contact"
                >
                  <div className="d-flex justify-content-end">
                    <span
                      onClick={() => this.handleModalClose(onClose)}
                      className="icon-close close-btn modal-contact-close"
                    />
                  </div>
                  <h2 className="modal-contact-title">
                    <FormattedMessage id="modal.login.title"/>
                  </h2>
                  <p className="modal-contact-subtitle">
                    <FormattedMessage id="modal.login.subtitle"/>
                  </p>
                  <form onSubmit={this.submitLogin}>
                    <div className="form-group">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder={intl.formatMessage({id: 'modal.login.form.email'})}
                          value={email}
                          onChange={this.handleEmailChange}
                        />
                        {
                          showValidation && !email &&
                          <div className="modal-error-message modal-error-message--with-p-bottom">
                            <FormattedMessage id="modal.login.errors.email"/>
                        </div>
                      }
                    </div>
                    <div className="form-group">
                      <input
                          type="password"
                          className="form-control form-control-lg"
                          placeholder={intl.formatMessage({id: 'modal.login.form.password'})}
                          value={password}
                          onChange={this.handlePasswordChange}
                      />
                      {
                        showValidation && !password &&
                        <div className="modal-error-message modal-error-message--with-p-bottom">
                          <FormattedMessage id="modal.login.errors.password"/>
                        </div>
                      }
                      {
                        loginError && !loginSuccess &&
                        <div className="modal-error-message modal-error-message--with-p-bottom">
                          <FormattedMessage id="modal.login.errors.invalid"/>
                        </div>
                      }
                    </div>
                    <div className="form-group d-flex justify-content-center">
                      <button className="btn btn-block btn-primary-inverse modal-contact-confirm">
                        <FormattedMessage id="modal.login.form.submit" />
                      </button>
                    </div>
                  </form>
                  <p className="modal-footer-text text-center">
                    <em>
                      <FormattedMessage id="modal.login.info"/>
                    </em>
                    <span
                      className="content-link link-primary"
                      onClick={() => this.handleRegisterModalOpen(onClose)}
                    >
                      <FormattedMessage id="modal.login.link"/>
                    </span>
                    <br/><br/>
                    <span
                      className="person-footer"
                      onClick={() => this.handleResetPasswordModalOpen(onClose)}
                    >
                      Reset password
                    </span>
                  </p>
                </Modal>
              );
            }
          }
        </ModalConnector>
      );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  open: PropTypes.func.isRequired,
  loginSuccess: PropTypes.bool,
  loginError: PropTypes.bool,
};

export default connect(
    (state) => ({
        loginError: state.login.isError,
        loginLoading: state.login.isLoading,
        loginSuccess: state.login.isSuccess,
        loginData: state.login.data,
    }),
    ({
      login,
      open,
    }),
)(injectIntl(Login));
