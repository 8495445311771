import * as React from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import { changeLanguage } from '../actions/langActions';

import ls from '../services/LocalStorageService';

import messagesEn from '../assets/i18n/en.json';
import messagesPl from '../assets/i18n/pl.json';
import messagesHr from '../assets/i18n/hr.json';

const messagesData = { en: messagesEn, pl: messagesPl, hr: messagesHr };
class IntlWrapper extends React.Component {
    componentDidMount() {
        const { language, changeLanguage } = this.props;
        const langFromLS = ls.get('lang');

        if (!langFromLS) {
            return ls.set('lang', language);
        }

        changeLanguage(langFromLS);
    }

    render() {
        const { language } = this.props;
        const messages = (messagesData)[language] || messagesEn;

        return (
            <IntlProvider locale={language} messages={messages}>
                {this.props.children}
            </IntlProvider>
        );
    }
}

IntlWrapper.propTypes = {
    language: propTypes.string,
    changeLanguage: propTypes.func,
    children: propTypes.node.isRequired,
};

export default connect(
    (state) => ({
        language: state.language,
    }),
    (dispatch) => ({
        changeLanguage: (value) => dispatch(changeLanguage(value)),
    }),
)(IntlWrapper);
