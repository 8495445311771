function renderQuizCfg(index) {
    return (
      {
        answers: [
          {
              answerID: `materials.quiz.q${index}.a1`,
              correct: true,
          },
          {
              answerID: `materials.quiz.q${index}.a2`,
              correct: false,
          },
          {
              answerID: `materials.quiz.q${index}.a3`,
              correct: false,
          },
        ],
        hintID: `materials.quiz.q${index}.hint`,
        questionID: `materials.quiz.q${index}`,
      }
    );
}

export const quizConfig = [
    renderQuizCfg(1),
    renderQuizCfg(2),
    renderQuizCfg(3),
    renderQuizCfg(4),
    renderQuizCfg(5),
    renderQuizCfg(6),
    renderQuizCfg(7),
    renderQuizCfg(8),
    renderQuizCfg(9),
    renderQuizCfg(10),
    renderQuizCfg(11),
    renderQuizCfg(12),
    renderQuizCfg(13),
    renderQuizCfg(14),
    renderQuizCfg(15),
    renderQuizCfg(16),
    renderQuizCfg(17),
    renderQuizCfg(18),
    renderQuizCfg(19),
    renderQuizCfg(20),
];
