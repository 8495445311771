import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import * as classnames from 'classnames';
import Slider from 'react-slick';

import { partnersLogos } from './config';

const settings = {
  autoplay: true,
  arrows: true,
  dots: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
          slidesToShow: 1,
      },
    },
  ],
  slidesToScroll: 1,
  slidesToShow: 5,
  speed: 500,
  swipeToSlide: false,
  touchMove: false,
  variableWidth: false,
};

class Logos extends React.Component {
  render() {
    return (
      <div className="logos">
        <div className="container">
          <div className="row">
            <div className="col-12 overflow-hidden">
              <h4 className="section-heading">
                <FormattedMessage id="page.about.partners.title" />
              </h4>
            </div>
            <div className="col-12 py-3">
              <div className="px-3 px-md-0">
                <Slider {...settings}>
                    {
                      partnersLogos.map((logo) => {
                        return (
                          <div key={logo.id} className="px-3">
                            <a href={logo.link} target="_blank" className="logo-wrapper" rel="noopener noreferrer">
                              <img
                                src={logo.bw}
                                className={classnames('img-fluid mx-auto logo-image logo-bw', {
                                    'w-50': logo.id === 'pwr',
                                })}
                              />
                              <img
                                src={logo.color}
                                className={classnames('img-fluid mx-auto logo-image logo-color', {
                                    'w-50': logo.id === 'pwr',
                                })}
                              />
                            </a>
                          </div>
                        );
                      })
                    }
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Logos;
