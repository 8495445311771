import React from 'react';
import PropTypes from 'prop-types';

class Loader extends React.Component {

  returnProgressWidth = () => {
    const { progress } = this.props;

    if (progress > 100) {
      return '0%';
    }
    return progress+'%';
  };

  render() {
    const {  showText } = this.props;

    return (
      <div className="loader">
        {showText && <span className="loader__text">LOADING...</span>}
        <div className="loader__bar">
          <div className="loader__container">
            <div className="loader__progress" style={{ width: this.returnProgressWidth() }} />
            <div className="loader__progress-background" />
          </div>
        </div>
      </div>
    );
  }
}

Loader.propTypes = {
  progress: PropTypes.number,
  showText: PropTypes.bool,
};

export default Loader;
