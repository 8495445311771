/* global require */

function importAll(r) {
  return r.keys().map(r);
}

const plaudio = importAll(require.context('../../assets/lessons/pl/audio', false, /\.pdf$/));
const enaudio = importAll(require.context('../../assets/lessons/en/audio', false, /\.pdf$/));
const hraudio = importAll(require.context('../../assets/lessons/hr/audio', false, /\.pdf$/));

export const lessonsMediaConfig = {
  pl: plaudio,
  en: enaudio,
  hr: hraudio
};