import * as _ from 'lodash';

export default class QuizService {
  shuffle(questions, questionsNumber) {
    const q = _.shuffle(questions).slice(0, questionsNumber);

    const shuffledQuestions = q.map((question) => {
      return{
        ...question,
        answers: _.shuffle(question.answers),
      };
    });

    return shuffledQuestions;
  }

  generateShowHintArray(quizQuestions, quizStage) {
    const actualQuestionAnswers = quizQuestions[quizStage - 1].answers;
    return Array.from(Array(actualQuestionAnswers.length)).map(() => false);
  }

  generateQuizQuestionPoints(answersNumber) {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
    return [...(alphabet.slice(0, answersNumber))];
  }
}
