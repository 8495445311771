import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {connect} from 'react-redux';

import * as modalActions from '../../actions/modals';

import ico01 from '../../assets/svg/01_educational.svg';
import ico02 from '../../assets/svg/02_vr.svg';
import ico03 from '../../assets/svg/03_unlock.svg';

class Gain extends React.Component {
  render() {
    return (
      <div className="gain py-5">
        <div className="container">
            <h3 className="display display--dark text-center">
                <FormattedMessage id="page.home.gain.title" />
            </h3>
            <div className="row py-4">
                <div className="col-md-4 gain__item">
                    <img src={ico01} className="mx-auto gain__image" />
                    <p className="gain__item__text"><FormattedMessage id="page.home.gain.item1.title" /></p>
                </div>
                <div className="col-md-4 gain__item">
                    <img src={ico02} className="mx-auto gain__image" />
                    <p className="gain__item__text"><FormattedMessage id="page.home.gain.item2.title" /></p>
                </div>
                <div className="col-md-4 gain__item">
                    <img src={ico03} className="mx-auto gain__image" />
                    <p className="gain__item__text"><FormattedMessage id="page.home.gain.item3.title" /></p>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

Gain.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default connect(
  null,
  ({
    openModal: modalActions.open,
  })
  )(Gain);
