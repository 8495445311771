/* global require */

function importAll(r) {
  return r.keys().map(r);
}

function toObject(arr) {
  return arr.reduce((obj, char) => {
    obj[char.substring(19, char.length-4)] = char;
    return obj;
  }, {});
}

const audio1 = importAll(require.context('../../assets/mp3/audio1', false, /\.mp3$/));
const audio2 = importAll(require.context('../../assets/mp3/audio2', false, /\.mp3$/));
const audio3 = importAll(require.context('../../assets/mp3/audio3', false, /\.mp3$/));
const audio4 = importAll(require.context('../../assets/mp3/audio4', false, /\.mp3$/));
const audio5 = importAll(require.context('../../assets/mp3/audio5', false, /\.mp3$/));
const audio6 = importAll(require.context('../../assets/mp3/audio6', false, /\.mp3$/));
const audio7 = importAll(require.context('../../assets/mp3/audio7', false, /\.mp3$/));
const audio8 = importAll(require.context('../../assets/mp3/audio8', false, /\.mp3$/));
const audio9 = importAll(require.context('../../assets/mp3/audio9', false, /\.mp3$/));
const audio = audio1.concat(audio2, audio3, audio4, audio5, audio6, audio7, audio8, audio9);

export const lessonsSoundConfig = toObject(audio);
