import * as React from 'react';
import {Modal} from 'reactstrap';
import { FormattedMessage } from 'react-intl';


import ModalConnector from '../Generic/Modals/ModalConnector';
import names from '../modals/names';

import './MediaModal.scss';

class MediaModal extends React.Component {
  render() {
    return (
      <ModalConnector name={names.MEDIA_MODAL}>
      {
        ({isOpen, data, onClose}) => {
          return (
            <Modal
              isOpen={isOpen}
              toggle={onClose}
              centered={true}
              className="text-center"
              contentClassName="modal-width"
              size={'lg'}
            >
              <div className="media-modal">
                <div className="d-flex justify-content-end">
                  <span
                    onClick={() => onClose()}
                    className="icon-close close-btn modal-contact-close"
                  />
                </div>
                <h2 className="title"><FormattedMessage id={data.title}/></h2>
                <p className="text">
                  <FormattedMessage id={data.text}/>
                </p>
                <div className="images-wrapper">
                  {data.images && data.images.map((item, key) => {
                    return (
                      <img key={key} src={item} alt=""/>
                    );
                  })}
                </div>
                <div>
                  <span className="btn-custom-primary" onClick={() => onClose()}>
                    <FormattedMessage id="modal.media.btn.text"/>
                  </span>
                </div>
              </div>
            </Modal>
          );
        }
      }
      </ModalConnector>
    );
  }
}

export default MediaModal;
