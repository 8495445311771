import * as React from 'react';
import {Modal} from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import ModalConnector from '../Generic/Modals/ModalConnector';
import names from '../modals/names';

import ico1 from '../../assets/svg/lesson_modal_ico_1.svg';
import ico2 from '../../assets/svg/lesson_modal_ico_2.svg';
import ico3 from '../../assets/svg/lesson_modal_ico_3.svg';
import ico4 from '../../assets/svg/lesson_modal_ico_4.svg';

import './LessonInfoModal.scss';

class LessonInfoModal extends React.Component {
  render() {
    return (
      <ModalConnector name={names.LESSON_INFO_MODAL}>
      {
        ({isOpen, onClose}) => {
          return (
            <Modal
              isOpen={isOpen}
              toggle={onClose}
              centered={true}
              className="text-center"
              contentClassName="modal-width"
              size={'lg'}
            >
              <div className="lesson-modal">
                <div className="d-flex justify-content-end">
                  <span
                    onClick={() => onClose()}
                    className="icon-close close-btn modal-contact-close"
                  />
                </div>
                <h2 className="title"><FormattedMessage id="modal.lesson.title"/></h2>
                <p className="text">
                  <FormattedMessage id="modal.lesson.text"/>
                </p>
                <div className="images-wrapper">
                  <div className="image-wrapper">
                    <img className="image" src={ico1} alt=""/>
                    <div className="wrapper-text">
                      <FormattedHTMLMessage id="modal.lesson.text.1" />
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <img className="image" src={ico2} alt=""/>
                    <div className="wrapper-text">
                      <FormattedHTMLMessage id="modal.lesson.text.2" />
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <img className="image" src={ico3} alt=""/>
                    <div className="wrapper-text">
                      <FormattedHTMLMessage id="modal.lesson.text.3" />
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <img className="image" src={ico4} alt=""/>
                    <div className="wrapper-text">
                      <FormattedHTMLMessage id="modal.lesson.text.4" />
                    </div>
                  </div>
                </div>
                <div>
                  <span className="btn-custom-primary" onClick={() => onClose()}>
                    <FormattedMessage id="modal.lesson.btn.text"/>
                  </span>
                </div>
              </div>
            </Modal>
          );
        }
      }
      </ModalConnector>
    );
  }
}

export default LessonInfoModal;
