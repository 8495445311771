import * as React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {Modal} from 'reactstrap';

import { register } from '../../actions/authActions';

import ModalConnector from '../Generic/Modals/ModalConnector';
import names from './names';

import termsOfUse from '../../assets/docs/terms_of_use.pdf';

import './Contact.scss';

class Register extends React.Component {

  state = {
    acceptTerms: false,
    email: '',
    password: '',
    repeatPassword: '',
    showError: false,
    showValidation: false,
  };

  validateEmail(email) {
    const reg = new RegExp([
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))/.source,
      /@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.source,
    ].join(''));

      return email.length >= 2 && email.length <= 180 && reg.test(email);
  }

  validatePassword(password, repeatPassword) {
      return password.length >= 8 && password.length <= 4096 && password === repeatPassword;
  }

  registerDataValid() {
      const {  acceptTerms, email, password, repeatPassword } = this.state;

      return this.validateEmail(email) && this.validatePassword(password, repeatPassword) && acceptTerms;
  }

  handleModalClose = (onClose) => {
    onClose();
  };

  handleEmailChange = (e) => {
      this.setState({
          email: e.target.value,
      });
  };

  handlePasswordChange = (e) => {
      this.setState({
          password: e.target.value,
      });
  };

  handleRepeatPasswordChange = (e) => {
      this.setState({
          repeatPassword: e.target.value,
      });
  };

  handleAcceptTermsChange = (e) => {
      this.setState({
          acceptTerms: e.target.checked,
      });
  };

  submitRegister = (e) => {
      e.preventDefault();

      this.setState({
          showError: true,
      });

      if (!this.registerDataValid()) {
          return this.setState({
              showValidation: true,
          });
      }

      const data = {
          email: this.state.email.trim(),
          password: this.state.password.trim(),
      };


      this.props.register(data);
  };

  registerSubmitBtn = () => {
      if (this.props.registerLoading) {
          return (
              <div className="submit-loading">
                  <div className="submit-loading__dot"></div>
                  <div className="submit-loading__dot"></div>
                  <div className="submit-loading__dot"></div>
                  <div className="submit-loading__dot"></div>
              </div>
          );
      }

      if (this.props.registerSuccess) {
          return <FormattedMessage id="modal.registerSuccess.title" />;
      }

      if (!this.props.registerLoading && !this.props.registerSuccess) {
          return this.props.intl.formatMessage({id: 'modal.register.form.submit'});
      }
  };

  renderErrorMessage() {
      const { registerError, registerErrorData } = this.props;

      if (registerError && registerErrorData.status === 409) {
        return (
          <div className="modal-error-message">
            <FormattedMessage id="modal.register.errors.taken" />
          </div>
        );
      }

    return null;
  }

  render() {
    const { intl, registerSuccess } = this.props;
    const { acceptTerms, email, password, repeatPassword, showValidation } = this.state;

    return (
      <ModalConnector name={names.REGISTER}>
        {
          ({isOpen, onClose}) => {
            return (
              <Modal
                size="md"
                isOpen={isOpen}
                toggle={() => this.handleModalClose(onClose)}
                contentClassName="modal-contact-content"
                className="modal-contact"
              >
                <div className="d-flex justify-content-end">
                  <span
                    onClick={() => this.handleModalClose(onClose)}
                    className="icon-close close-btn modal-contact-close"
                  />
                </div>
                <h2 className="modal-contact-title">
                    <FormattedMessage id="modal.register.title" />
                </h2>
                <p className="modal-contact-subtitle">
                    <FormattedMessage id="modal.register.subtitle" />
                    <strong>
                        <FormattedMessage id="modal.register.subtitle.ace" />
                    </strong>,
                    <strong>
                        <FormattedHTMLMessage id="modal.register.subtitle.arac" />
                    </strong>
                    <FormattedMessage id="modal.register.subtitle.and" />
                    <strong>
                        <FormattedMessage id="modal.register.subtitle.scientific" />
                    </strong>
                </p>
                <form onSubmit={this.submitRegister}>
                    <div className="form-group">
                        <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder={intl.formatMessage({id: 'modal.register.form.email'})}
                            value={email}
                            onChange={this.handleEmailChange}
                        />
                        {showValidation && !this.validateEmail(email) && <div className="modal-error-message">
                            <FormattedMessage id="modal.register.errors.email" />
                        </div>}
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            className="form-control form-control-lg"
                            placeholder={intl.formatMessage({id: 'modal.register.form.password'})}
                            value={password}
                            onChange={this.handlePasswordChange}
                        />
                        {
                            this.state.showValidation &&
                            !this.validatePassword(password, repeatPassword) &&
                            <div className="modal-error-message">
                                <FormattedMessage id="modal.register.errors.password" />
                            </div>
                        }
                    </div>
                    <div className="form-group">
                        <input type="password"
                            className="form-control form-control-lg"
                            placeholder={intl.formatMessage({id: 'modal.register.form.repassword'})}
                            value={repeatPassword}
                            onChange={this.handleRepeatPasswordChange}
                        />
                    </div>
                    <div className="form-check">
                      <label className="form-check-label form-check-checkbox">
                          <input
                            type="checkbox"
                            className="form-check-input form-check-input--opacity"
                            checked={acceptTerms}
                            onChange={this.handleAcceptTermsChange}
                          />
                          <span className="form-check-fake-input" />
                          <p>
                            <FormattedMessage id="modal.register.form.checkbox-info" />
                            &nbsp;
                            <a
                              className="info-link link-secondary"
                              href={termsOfUse}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              &nbsp;<FormattedMessage id="modal.register.terms" />&nbsp;
                            </a>
                          </p>
                      </label>
                      {showValidation && !acceptTerms && <div className="modal-error-message">
                        <FormattedMessage id="modal.register.errors.accept" />
                      </div>}
                      {registerSuccess && <div className="modal-success-message">
                        <FormattedMessage id="modal.register.success" />
                      </div>}
                      {this.renderErrorMessage()}
                    </div>
                    <div className="form-group d-flex justify-content-center">
                      <button className="btn btn-block btn-primary-inverse modal-contact-confirm">
                        <FormattedMessage id="modal.register.form.submit" />
                      </button>
                    </div>
                </form>
              </Modal>
            );
          }
        }
      </ModalConnector>
    );
  }
}

Register.propTypes = {
  intl: PropTypes.object,
  registerSuccess: PropTypes.bool,
  registerError: PropTypes.bool,
  registerErrorData: PropTypes.object,
  registerLoading: PropTypes.bool,
  register: PropTypes.func,

};

export default connect(
    (state) => ({
        registerError: state.register.isError,
        registerLoading: state.register.isLoading,
        registerSuccess: state.register.isSuccess,
        registerErrorData: state.register.error,
    }),
    ({
        register,
    }),
)(injectIntl(Register));
