import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import YouTube from 'react-youtube';

import PropTypes from 'prop-types';

import modalActions from '../../actions/modals';
import modalNames from '../../components/modals/names';

import headphones from '../../assets/svg/headphones.svg';
import vrset from '../../assets/svg/vrset.svg';
import SideMenu from '../SideMenu/SideMenu';
import vrIco from '../../assets/svg/vr_ico.svg';
import playBtn from '../../assets/svg/yt_play.svg';

import { lessonsVRConfig } from './LessonsVRConfig';

import './LessonMedia.scss';

const lessonsSideMenuConfig = {
  ico: vrIco,
  title: 'sidemenu.vr.title',
  menu: [{
    groupName: 'sidemenu.vr.group1',
    data: [
      {
        text: 'sidemenu.vr.group1.item1',
        link: '/materials/video/8/1'
      }
    ]
  }, {
    groupName: 'sidemenu.vr.group2',
    data: [
      {
        text: 'sidemenu.vr.group2.item1',
        link: '/materials/video/8/2'
      },
      {
        text: 'sidemenu.vr.group2.item2',
        link: '/materials/video/8/3'
      },
      {
        text: 'sidemenu.vr.group2.item3',
        link: '/materials/video/8/4'
      },
      {
        text: 'sidemenu.vr.group2.item4',
        link: '/materials/video/8/5'
      },
      {
        text: 'sidemenu.vr.group2.item5',
        link: '/materials/video/8/6'
      },
      {
        text: 'sidemenu.vr.group2.item6',
        link: '/materials/video/8/7'
      },
      {
        text: 'sidemenu.vr.group2.item7',
        link: '/materials/video/8/8'
      },
      {
        text: 'sidemenu.vr.group2.item8',
        link: '/materials/video/8/9'
      }
    ]
  }]
};

let player = null;

class LessonVideo extends React.Component {
  state = {
    isPlayVisible: false,
  };

  componentDidMount() {
    const { videoPage } = this.props.match.params;

    if(videoPage !== '1') {
      return;
    }

    return this.props.openModal(modalNames.MEDIA_MODAL,
      {
        title: 'modal.media.vr.title',
        text: 'modal.media.vr.text',
        images: [headphones, vrset]
      });
  }

  onReady = (event) => {
    player = event.target;
    this.setState({
      isPlayVisible: true
    });
  };

  playVideo = () => {
    this.setState({
      isPlayVisible: false
    });
    player.playVideo();
  };

  onEnd = () => {
    this.setState({
      isPlayVisible: true
    });
  };

  onStateChange = (state) => {
    if (state.data === 1 || state.data === 3) {
      this.setState({
        isPlayVisible: false
      });
    }

    if (state.data === -1) {
      this.setState({
        isPlayVisible: true
      });
    }
  };

  render() {
    const { videoPage } = this.props.match.params;

    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        'rel': 0,
        showinfo: 0
      }
    };

    return (
      <div className="lesson-media">
        <SideMenu
          ico={lessonsSideMenuConfig.ico}
          title={lessonsSideMenuConfig.title}
          menu={lessonsSideMenuConfig.menu}
        />
        <div className="video-content">
          <YouTube
            videoId={lessonsVRConfig[parseInt(videoPage) - 1]}
            opts={opts}
            onReady={this.onReady}
            onEnd={this.onEnd}
            onStateChange={this.onStateChange} 
          />
          <img className={classnames('yt-play-btn', {
            'visible': this.state.isPlayVisible
          })} onClick={this.playVideo} src={playBtn}/>
        </div>
      </div>
    );
  }
}

LessonVideo.propTypes = {
  match: PropTypes.object,
  language: PropTypes.string,
  openModal: PropTypes.func,
};

export default connect(
  (state) => ({
    language: state.language
  }),
  ({
    openModal: modalActions.open,
  })
)(LessonVideo);
