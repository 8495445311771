import React from 'react';

import LoggedLayout from './LoggedLayout';

class LoggedLayoutIntro extends React.Component {
  render() {
    const menuLinks = [
      { id: 'menu.goto.aci', to: '/' },
    ];

    return <LoggedLayout showBar={false} menuLinks={menuLinks} needToLogin={false} />;
  }
}

export default LoggedLayoutIntro;
