// For async/await
import '@babel/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

import './assets/styles/index.scss';

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);
