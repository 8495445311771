import * as React from 'react';

import TeamPersonView from './TeamPersonView';
import LightBoxModal from './LightBox';
import Contact from './Contact';
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';
import PdfViewModal from './PdfViewModal';
import MediaModal from './MediaModal';
import LessonInfoModal from './LessonInfoModal';

class AllModals extends React.Component {
  render() {
    return (
      <React.Fragment>
        <TeamPersonView />
        <LightBoxModal />
        <Contact />
        <Login />
        <Register />
        <ResetPassword />
        <PdfViewModal />
        <MediaModal/>
        <LessonInfoModal/>
      </React.Fragment>
    );
  }
}

export default AllModals;
