export const MODAL = {
  OPEN: 'MODAL/OPEN',
  CLOSE: 'MODAL/CLOSE',
  CLOSE_ACTUAL_OPENED: 'MODAL/CLOSE_ACTUAL_OPENED',
};

export const MAIL_SEND = {
  START: 'MAIL_SEND/START',
  ERROR: 'MAIL_SEND/ERROR',
  SUCCESS: 'MAIL_SEND/SUCCESS',
};

export const MAIL_RESET_DATA = 'MAIL_RESET_DATA';

export const LOGIN = {
  START: 'LOGIN/START',
  ERROR: 'LOGIN/ERROR',
  SUCCESS: 'LOGIN/SUCCESS',
};

export const LOGOUT = 'LOGOUT';

export const REGISTER = {
  START: 'REGISTER/START',
  ERROR: 'REGISTER/ERROR',
  SUCCESS: 'REGISTER/SUCCESS',
};

export const RESET_PASSWORD = {
  START: 'RESET_PASSWORD/START',
  ERROR: 'RESET_PASSWORD/ERROR',
  SUCCESS: 'RESET_PASSWORD/SUCCESS',
};

export const REDIRECT_URL_AFTER_LOGIN = {
  CLEAR: 'CLEAR_REDIRECT_URL_AFTER_LOGIN',
  SET: 'SET_REDIRECT_URL_AFTER_LOGIN',
};

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
