import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import './ChaptersInfo.scss';

class ChaptersInfo extends React.Component {

  renderData() {
    if(!this.props.info) {
      return null;
    }

    return this.props.info.map((item, index) => {
      if(item.type === 'text') {
        return (
          <p className="materials-chapters__text" key={index}>
            <FormattedHTMLMessage id={item.data} />
          </p>
        );
      }
      if(item.type === 'img') {
        return (
          <div className="materials-chapters__img" key={index}>
            <img src={item.data} alt=""/>
          </div>
        );
      }
      
    });
  }

  renderButtons() {
    if(!this.props.buttons) {
      return null;
    }

    return this.props.buttons.map((item, index) => {
      return (
        <div className="btn-custom-primary materials-chapters__btn" key={index}
          onClick={() => this.props.push(item.link)}
        >
          <FormattedMessage id={item.title}/>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="materials-chapters">
        <div className="materials-chapters__info-text">
          <h3 className="materials-chapters__info-title">
            <FormattedMessage id={this.props.title} />
          </h3>
          {this.renderData()}
        </div>
        <div className="materials-chapters__info-button">
          {this.renderButtons()}
        </div>
      </div>
    );
  }
}

ChaptersInfo.propTypes = {
  title: PropTypes.string,
  info: PropTypes.array,
  buttons: PropTypes.array,
  push: PropTypes.func,
};

export default connect(
  null,
  ({ push })
)(ChaptersInfo);
