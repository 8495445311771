import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactDOM from 'react-dom';

import './Select.scss';

class Select extends React.Component {
  state = {
    isShow: false,
  };

  handleClickOutside = (event) => {
    /* eslint-disable-next-line */
    const domNode = ReactDOM.findDOMNode(this);

    if ((!domNode || !domNode.contains(event.target))) {
      this.setState({
          isShow: false,
      });
    }
  };

  onClickOption(option) {
    const { onSelect } = this.props;

    if (onSelect && !option.disabled) {
      onSelect(option.value, option);
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  renderList(selected) {
    const { withNoSelected } = this.props;
    const options = withNoSelected
      ? this.props.options.filter((item) => item.label !== selected)
      : this.props.options;
    const listStyle = withNoSelected ? { borderTop: 'none' } : null;

    return (
      <ul style={listStyle}>
          {options.map((option) => {
            const { value, label, disabled } = option;

            return  (
              <li key={value} className={classnames({disabled: disabled === true})}
                onClick={() => this.onClickOption(option)}>
                {label}
              </li>
            );
          })}
      </ul>
    );
  }

  render() {
    const { isShow } = this.state;
    const { options, value, className } = this.props;

    const selectedOption = options.find((item) => item.value === value);

    return (
      <div className={classnames('select', className)}
        onClick={() => this.setState({
          isShow: !isShow,
        })}
      >
        <p>{selectedOption.label}</p>
        <div className="arrow-down-icon" />
        {isShow && this.renderList(selectedOption.label)}
      </div>
    );
  }
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  withNoSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default Select;
