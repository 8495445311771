import httpClient from './httpClient';

import CrossDomainMessage from './CrossDomainMessage';
import ls from './LocalStorageService';

export class ApiAuth {
  getUserName = (email) => {
    return email ? email.split('@')[0] : null;
  };

  register = (data) => {
    return httpClient.post('/api/v1/users/new', data);
  };

  activate = (token) => {
    const data = {
      confirmationToken: token,
    };

    return httpClient.post('/api/v1/users/activation', data);
  };

  resetPassword = (email) => {
    return httpClient.post('/api/v1/users/resetpassword', email);
  };

  changePassword = (data) => {
    return httpClient.patch('/api/v1/users/resetpassword', data);
  };

  login = (body) => {
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', '1_4fkb0s12cdk4c0gcooko4w4cw4co4kk004g8c88o4sc4kcwoow');
    formData.append('client_secret', '2e0oj69roq3ok0wk0wko4cc4o488okcscsogog4kw84kk004kg');
    formData.append('username', body.username);
    formData.append('password', body.password);

    return httpClient.post('/oauth/v2/token' , formData,
      {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      },
    )
    .then((res) => res.data)
    .then((responseData) => {
      const data = {
        ...responseData,
        username: body.username,
      };

      this.saveAuth(data);

      return data;
    });
  };

  isLogged = () => {
    const auth = ls.get('auth');

    return auth ? auth.access_token : !!auth;
  };

  logout = () => {
    this.logoutSelf();
    CrossDomainMessage.sendMessageToAll('onLogout');
  };

  setHeaders() {
    this.saveAuthSelf(this.getAuth());
  }

  saveAuth = (authData) => {
    this.saveAuthSelf(authData);
    CrossDomainMessage.sendMessageToAll('onLogin', authData);
  };

  logoutSelf = () => {
    ls.remove('auth');
    // tslint:disable-next-line
    httpClient.defaults.headers.common['Authorization'] = undefined;
  };

  saveAuthSelf = (authData) => {
    // tslint:disable-next-line
    httpClient.defaults.headers.common['Authorization'] = `${authData.token_type} ${authData.access_token}`;
    ls.set('auth', authData);
  };

  getAuth = () => {
    return ls.get('auth');
  };

  getMe = () => {
    return httpClient.get('/api/v1/users/me');
  };
}

export default new ApiAuth();
