/* global require */

// naming, due to changes
// `lesson` === `case`, in code it is named as `lesson`, but we show it to end-user as `case`  

function importAll(r) {
  return r.keys().map(r);
}

const plLesson1 = importAll(require.context('../../assets/lessons/pl/lesson1/pages', false, /\.pdf$/));
const plLesson2 = importAll(require.context('../../assets/lessons/pl/lesson2/pages', false, /\.pdf$/));
const plLesson3 = importAll(require.context('../../assets/lessons/pl/lesson3/pages', false, /\.pdf$/));
const plLesson4 = importAll(require.context('../../assets/lessons/pl/lesson4/pages', false, /\.pdf$/));
const plLesson5 = importAll(require.context('../../assets/lessons/pl/lesson5/pages', false, /\.pdf$/));
const plLesson6 = importAll(require.context('../../assets/lessons/pl/lesson6/pages', false, /\.pdf$/));
const plLesson7 = importAll(require.context('../../assets/lessons/pl/lesson7/pages', false, /\.pdf$/));

const enLesson1 = importAll(require.context('../../assets/lessons/en/lesson1/pages', false, /\.pdf$/));
const enLesson2 = importAll(require.context('../../assets/lessons/en/lesson2/pages', false, /\.pdf$/));
const enLesson3 = importAll(require.context('../../assets/lessons/en/lesson3/pages', false, /\.pdf$/));
const enLesson4 = importAll(require.context('../../assets/lessons/en/lesson4/pages', false, /\.pdf$/));
const enLesson5 = importAll(require.context('../../assets/lessons/en/lesson5/pages', false, /\.pdf$/));
const enLesson6 = importAll(require.context('../../assets/lessons/en/lesson6/pages', false, /\.pdf$/));
const enLesson7 = importAll(require.context('../../assets/lessons/en/lesson7/pages', false, /\.pdf$/));

const hrLesson1 = importAll(require.context('../../assets/lessons/hr/lesson1/pages', false, /\.pdf$/));
const hrLesson2 = importAll(require.context('../../assets/lessons/hr/lesson2/pages', false, /\.pdf$/));
const hrLesson3 = importAll(require.context('../../assets/lessons/hr/lesson3/pages', false, /\.pdf$/));
const hrLesson4 = importAll(require.context('../../assets/lessons/hr/lesson4/pages', false, /\.pdf$/));
const hrLesson5 = importAll(require.context('../../assets/lessons/hr/lesson5/pages', false, /\.pdf$/));
const hrLesson6 = importAll(require.context('../../assets/lessons/hr/lesson6/pages', false, /\.pdf$/));
const hrLesson7 = importAll(require.context('../../assets/lessons/hr/lesson7/pages', false, /\.pdf$/));

export const lessonsConfig = {
  pl: {
    1: plLesson1,
    2: plLesson2,
    3: plLesson3,
    4: plLesson4,
    5: plLesson5,
    6: plLesson6,
    7: plLesson7
  },
  en: {
    1: enLesson1,
    2: enLesson2,
    3: enLesson3,
    4: enLesson4,
    5: enLesson5,
    6: enLesson6,
    7: enLesson7,
  },
  hr: {
    1: hrLesson1,
    2: hrLesson2,
    3: hrLesson3,
    4: hrLesson4,
    5: hrLesson5,
    6: hrLesson6,
    7: hrLesson7,
  }
};