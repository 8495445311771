import React from 'react';

import SocialBar from '../../components/SocialBar/SocialBar';
import ACI from './ACI';
import Gain from './Gain';
import Hero from './Hero';


function HomePage() {
  return (
    <React.Fragment>
      <SocialBar />;
      <Hero />
      <Gain />
      <ACI />
    </React.Fragment>

  );
}

export default HomePage;
