export const lessonsVRConfig = [
  'xrz9Kxy5RwM',
  'DI30xPDABcs',
  'sFG2RPL6apQ',
  'TI2NxRxo9Dk',
  'K0FHC-hi9X8',
  'DGrmjSNMLx4',
  '7n8NcI0WVt8',
  'VLokbnmTOvw',
  'xJ2cElmRK1A'
];
