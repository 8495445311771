/* global require */

function importAll(r) {
  return r.keys().map(r);
}

function toObject(arr) {
  return arr.reduce((obj, char) => {
    obj['modal' + char.substring(40, char.length - 4)] = char;
    return obj;
  }, {});
}


const plModals2 = importAll(require.context('../../assets/lessons/pl/lesson2/modals', false, /\.pdf$/));
const plModals4 = importAll(require.context('../../assets/lessons/pl/lesson4/modals', false, /\.pdf$/));
const plModals5 = importAll(require.context('../../assets/lessons/pl/lesson5/modals', false, /\.pdf$/));
const plModals6 = importAll(require.context('../../assets/lessons/pl/lesson6/modals', false, /\.pdf$/));
const plModals7 = importAll(require.context('../../assets/lessons/pl/lesson7/modals', false, /\.pdf$/));
const plModals = plModals4.concat(plModals2, plModals4, plModals5, plModals6, plModals7);

const enModals2 = importAll(require.context('../../assets/lessons/en/lesson2/modals', false, /\.pdf$/));
const enModals4 = importAll(require.context('../../assets/lessons/en/lesson4/modals', false, /\.pdf$/));
const enModals5 = importAll(require.context('../../assets/lessons/en/lesson5/modals', false, /\.pdf$/));
const enModals6 = importAll(require.context('../../assets/lessons/en/lesson6/modals', false, /\.pdf$/));
const enModals7 = importAll(require.context('../../assets/lessons/en/lesson7/modals', false, /\.pdf$/));
const enModals = enModals2.concat(enModals4, enModals5, enModals6, enModals7);

const hrModals2 = importAll(require.context('../../assets/lessons/hr/lesson2/modals', false, /\.pdf$/));
const hrModals4 = importAll(require.context('../../assets/lessons/hr/lesson4/modals', false, /\.pdf$/));
const hrModals5 = importAll(require.context('../../assets/lessons/hr/lesson5/modals', false, /\.pdf$/));
const hrModals6 = importAll(require.context('../../assets/lessons/hr/lesson6/modals', false, /\.pdf$/));
const hrModals7 = importAll(require.context('../../assets/lessons/hr/lesson7/modals', false, /\.pdf$/));
const hrModals = hrModals2.concat(hrModals4, hrModals5, hrModals6, hrModals7); 

export const lessonsModalConfig = {
  pl: toObject(plModals),
  en: toObject(enModals),
  hr: toObject(hrModals)
};
