import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { push } from 'connected-react-router';
import ls from '../../services/LocalStorageService';

import modalActions from '../../actions/modals';
import modalNames from '../../components/modals/names';

import PdfLoader from '../PdfLoader/PdfLoader';

import { lessonsConfig } from './LessonsConfig';
import { lessonsModalConfig } from './LessonsModalConfig';
import { lessonsSoundConfig } from '../LessonMedia/LessonsSoundConfig';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import Sound from 'react-sound';

class Lesson extends React.Component {
  state = {
    soundUrl: '',
    status: 'STOPPED'
  };

  componentDidMount() {
    const isModalShowed = ls.get('isModalShowed');

    if(isModalShowed) {
      return;
    }

    ls.set('isModalShowed', true);
    this.props.openModal(modalNames.LESSON_INFO_MODAL);
  }

  componentDidUpdate(prevProps) {
    if(this.props.match.url !== prevProps.match.url) {
      this.setState({
        status: 'STOPPED',
        soundUrl: ''
      });
    }
  }
  
  onLinkClick = (link) => {
    const { language } = this.props;
    
    const linkName = link.href.replace('http://', '').slice(0, -1);

    if(linkName.includes('modal')) {
      this.props.openModal(modalNames.PDF_VIEW,
        { file: lessonsModalConfig[language][linkName] });
    }

    if(linkName.includes('page')) {
      const lesson = linkName.split('_')[1];
      const lessonPage = linkName.split('_')[2];
      this.props.push(`/materials/lesson/${lesson}/${lessonPage}`);
    }

    if(linkName.includes('audio')) {
      if(lessonsSoundConfig[linkName]) {
        this.setState({
          status: 'PLAYING',
          soundUrl: lessonsSoundConfig[linkName]
        });
      }
    }
  };

  onStatusClick = (status) => {
    this.setState({
      status: status,
    });
  };

  render() {
    const { lesson, page } = this.props.match.params;
    const { language } = this.props;
    const {status, soundUrl} = this.state;

    return (
      <React.Fragment>
        <PdfLoader
          file={lessonsConfig[language][lesson][parseInt(page) - 1]}
          onLinkClick={this.onLinkClick}
          isZoom={true}
        />
        <AudioPlayer
          soundUrl={soundUrl}
          playStatus={Sound.status[status]}
          onStopClick={() => this.onStatusClick('STOPPED')}
          onPauseClick={() => this.onStatusClick('PAUSED')}
          onPlayClick={() => this.onStatusClick('PLAYING')}
        />
      </React.Fragment>
    );
  }
}

Lesson.propTypes = {
  match: PropTypes.object,
  push: PropTypes.func,
  language: PropTypes.string,
  openModal: PropTypes.func,
};

export default connect(
  (state) => ({
    language: state.language
  }),
  ({
    openModal: modalActions.open,
    push
  })
)(Lesson);
