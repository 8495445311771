import {MAIL_SEND, MAIL_RESET_DATA} from './types';

export const sendMail = (data) => ({
  type: MAIL_SEND.START,
  payload: {
    data,
  },
});

export const resetMailData = () => ({
  type: MAIL_RESET_DATA,
});

export default {
  sendMail,
  resetMailData,
};
