import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import './ACI.scss';

function ACI() {
  return (
    <div className="aci py-5">
      <div className="container-fluid p-0">
        <h3 className="display display--dark text-center">
          <FormattedMessage id="page.home.aci.title" />
        </h3>
        <p className="aci__info text-center py-4">
          <FormattedHTMLMessage id="page.home.aci.p1" />
        </p>
        <div className="aci__info-wrapper d-flex align-items-center">
          <div className="col-12 col-md-10 col-lg-6 mx-auto aci__info-box py-5">
            <h3 className="display display--darkened text-center mb-5">
              <FormattedMessage id="page.home.aci.p2" />
            </h3>
            <p className="aci__subtitle">
              <FormattedMessage id="page.home.aci.meanwhile" />
            </p>
            <div className="col-lg-8 offset-lg-2">
              <a
                href="https://acoucou.org/"
                className="btn btn-lg btn-block btn-secondary btn-secondary--dark-text"
              >
                <FormattedMessage id="page.home.aci.btn1" />
              </a>
            </div>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-lg-4 offset-lg-4 col-8 offset-2">
            <Link
              to="/about"
              className="btn btn-lg btn-block btn-secondary btn-secondary--dark-text"
            >
              <FormattedMessage id="page.home.aci.btn2" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ACI;
