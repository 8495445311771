import { hot } from 'react-hot-loader';
import React from 'react';
import propTypes from 'prop-types';
import { Provider } from 'react-redux';
import { addLocaleData } from 'react-intl';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import GlobalLayout from './GlobalLayout/GlobalLayout.jsx';
import LoggedLayoutLesson from './LoggedLayout/LoggedLayoutLesson';
import LoggedLayoutIntro from './LoggedLayout/LoggedLayoutIntro';
import IntlWrapper from './IntlWrapper';

import ScrollToTop from './utils/ScrollToTop.jsx';
import CrossDomainMessage from '../services/CrossDomainMessage';

import AllModals from './modals/AllModals';
import * as TagManager from 'react-gtm-module';


CrossDomainMessage.init({
  acoucou: {
      domainUrl: 'http://master.acc-web.kfb.jzapp.io',
      iframeUrl: '/cross-domain',
  },
});

const gtmTag = window.appConfig.GTM_TAG;
if (gtmTag) {
    const tagManagerArgs = {
        gtmId: gtmTag,
    };
    TagManager.initialize(tagManagerArgs);
}

import * as en from 'react-intl/locale-data/en';
import * as pl from 'react-intl/locale-data/pl';
import * as hr from 'react-intl/locale-data/hr';

addLocaleData([...en, ...pl, ...hr]);

import store, { history } from '../store';

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <IntlWrapper>
          <ConnectedRouter history={history}>
            <ScrollToTop>
              <Switch>
                <Route path="/materials/lesson/:lesson/:page" component={LoggedLayoutLesson} />
                <Route path="/materials/media/:lesson/:audioPage" component={LoggedLayoutLesson} />
                <Route path="/materials/video/:lesson/:videoPage" component={LoggedLayoutLesson} />
                <Route path="/materials/quiz/:lesson" component={LoggedLayoutLesson} />
                <Route path="/materials/intro" component={LoggedLayoutIntro} />
                <Route path="/" component={GlobalLayout} />
              </Switch>
              <AllModals />
            </ScrollToTop>
          </ConnectedRouter>
        </IntlWrapper>
      </Provider>
    );
  }
}

App.propTypes = {
  history: propTypes.object,
};

export default hot(module)(App);
