import { LOGIN, LOGOUT, REDIRECT_URL_AFTER_LOGIN } from '../actions/types';
import ApiAuth from '../services/ApiAuth';

const defaultLoginData = {
  access_token: null,
  expires_in: null,
  refresh_token: null,
  scope: null,
  token_type: null,
  username: null,
};

const initialState = {
  isError: false,
  isLoading: false,
  isSuccess: false,
  data: ApiAuth.getAuth() || defaultLoginData,
  redirectUrlAfterLogin: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN.START:
      return {
        ...state,
        isLoading: true,
        data: null,
      };
    case LOGIN.ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
        data: null,
      };
    case LOGIN.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        data: action.payload,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    case REDIRECT_URL_AFTER_LOGIN.SET:
      return {
        ...state,
        redirectUrlAfterLogin: action.payload,
      };
    case REDIRECT_URL_AFTER_LOGIN.CLEAR:
      return {
        ...state,
        redirectUrlAfterLogin: null,
      };
    default:
      return state;
  }
};
