import jazzy from '../../assets/img/pages/partners/jazzy.png';
import jazzyColor from '../../assets/img/pages/partners/jazzy_color.png';
import kfb from '../../assets/img/pages/partners/kfb_color_inactive.png';
import kfbColor from '../../assets/img/pages/partners/kfb_color_active.png';
import kul from '../../assets/img/pages/partners/kul.png';
import kulColor from '../../assets/img/pages/partners/kul_color.png';
import pwr from '../../assets/img/pages/partners/pwr.png';
import pwrColor from '../../assets/img/pages/partners/pwr_color.png';
import fer from '../../assets/img/pages/partners/fer.png';
import ferColor from '../../assets/img/pages/partners/fer_color.png';
import teamMember1 from '../../assets/img/pages/about/baranski.jpg';
import teamMember2 from '../../assets/img/pages/about/karolina.jpg';
import teamMember3 from '../../assets/img/pages/about/chmielewski.jpg';
import teamMember4 from '../../assets/img/pages/about/malec.jpg';
import teamMember5 from '../../assets/img/pages/about/rudno.jpg';
import teamMember6 from '../../assets/img/pages/about/marcin.jpg';
import teamMember7 from '../../assets/img/pages/about/dobrucki.jpg';
import teamMember8 from '../../assets/img/pages/about/plaskota.jpg';
import teamMember9 from '../../assets/img/pages/about/bolejko.jpg';
import teamMember10 from '../../assets/img/pages/about/jambrosic.jpg';
import teamMember11 from '../../assets/img/pages/about/petosic.jpg';
import teamMember12 from '../../assets/img/pages/about/horvat.jpg';
import teamMember13 from '../../assets/img/pages/about/krhen.jpg';
import teamMember14 from '../../assets/img/pages/about/monika.jpg';
import teamMember15 from '../../assets/img/pages/about/yannick.jpg';
import teamMember16 from '../../assets/img/pages/about/piotrowska.jpg';
import teamMember17 from '../../assets/img/pages/about/lech.jpg';
import gallery1 from '../../assets/img/pages/about/aciabout_1.jpg';
import gallery7 from '../../assets/img/pages/about/aciabout_2.jpg';
import gallery3 from '../../assets/img/pages/about/aciabout_3.jpg';
import gallery4 from '../../assets/img/pages/about/aciabout_4.jpg';
import gallery5 from '../../assets/img/pages/about/aciabout_5.jpg';
import gallery6 from '../../assets/img/pages/about/aciabout_6.jpg';
import gallery2 from '../../assets/img/pages/about/aciabout_7.jpg';

export const partnersLogos = [
  {
      bw: jazzy,
      color: jazzyColor,
      id: 'jazzy',
      link: 'http://jazzy.pro',
  },
  {
      bw: kfb,
      color: kfbColor,
      id: 'kfb',
      link: 'http://www.kfb-polska.pl/',
  },
  {
      bw: kul,
      color: kulColor,
      id: 'kul',
      link: 'https://www.kuleuven.be/kuleuven/',
  },
  {
    bw: pwr,
    color: pwrColor,
    id: 'pwr',
    link: 'https://pwr.edu.pl/',
  },
  {
    bw: fer,
    color: ferColor,
    id: 'fer',
    link: 'https://www.fer.unizg.hr/'
  }
];

export const teamPhotos = {
  teamMember1,
  teamMember2,
  teamMember3,
  teamMember4,
  teamMember5,
  teamMember6,
  teamMember7,
  teamMember8,
  teamMember9,
  teamMember10,
  teamMember11,
  teamMember12,
  teamMember13,
  teamMember14,
  teamMember15,
  teamMember16,
  teamMember17,
};

export const gallery = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
];
