import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import * as modalActions from '../../actions/modals';
import modalNames from '../../components/modals/names';

import {teamPhotos} from './config';

const team = [
    {
      footer: <a className="person-footer" href="http://kfb-acoustics.com/">KFB Acoustics</a>,
      id: 'modal.person.info.filip-baranski',
      name: 'Filip Barański',
      picture: teamPhotos.teamMember1,
      position: 'CEO of KFB Acoustics',
    },
    {
      extraPosition: 'ACI Coordinator',
      footer: <a className="person-footer" href="http://www.kfb-acoustics.com/">KFB Acoustics</a>,
      id: 'modal.person.info.karolina-jaruszewska',
      name: 'Karolina Jaruszewska',
      picture: teamPhotos.teamMember2,
      position: 'Project Manager at Acoustics',
    },
    {
      footer: <a className="person-footer" href="http://www.kfb-acoustics.com/">KFB Acoustics</a>,
      id: 'modal.person.info.bartosz-chmielewski',
      name: 'Bartosz Chmielewski',
      picture: teamPhotos.teamMember3,
      position: 'Head of R&D at KFB Acoustics',
    },
    {
      footer: <a className="person-footer" href="http://www.kfb-acoustics.com/">KFB Acoustics</a>,
      id: 'modal.person.info.tomasz-malec',
      name: 'Tomasz Malec',
      picture: teamPhotos.teamMember4,
      position: 'Head of Acoustic Department at KFB Acoustics',
    },
    {
      id: 'modal.person.info.barbara-rudno-rudzinska',
      name: 'Barbara Rudno-Rudzińska',
      picture: teamPhotos.teamMember5,
      position: 'Researcher',
    },
    {
      footer: <a className="person-footer" href="https://www.linkedin.com/in/m-luczak">M. Łuczak</a>,
      id: 'modal.person.info.marcin-luczak',
      name: 'Marcin Łuczak',
      picture: teamPhotos.teamMember6,
      position: 'Content Manager at KFB Acoustics',
    },
    {
      footer: <div>
        <a className="person-footer" href="http://pwr.edu.pl/en">Wrocław University of Science and Technology</a>
        <br />
        <a className="person-footer" href="http://akustyka.pwr.edu.pl/dzialalnosc/biografie/andrzej-b-dobrucki/">
          Andrzej Dobrucki Research
        </a>
      </div>,
      id: 'modal.person.info.andrzej-dobrucki',
      name: 'Andrzej Dobrucki',
      picture: teamPhotos.teamMember7,
      position: 'Professor at Wrocław University of Science and Technology',
    },
    {
      footer: null,
      id: 'modal.person.info.przemyslaw-plaskota',
      name: 'Przemysław Plaskota',
      picture: teamPhotos.teamMember8,
      position: 'Assistant Professor at Wrocław University of Science and Technology',
    },
    {
      id: 'modal.person.info.romuald-bolejko',
      name: 'Romuald Bolejko',
      picture: teamPhotos.teamMember9,
      position: 'Assistant Professor at Wrocław University of Science and Technology',
    },
    {
      footer: <a className="person-footer" href="https://www.fer.unizg.hr/en/kristian.jambrosic">Research</a>,
      id: 'modal.person.info.kristian-jambrosic',
      name: 'Kristian Jambrošić',
      picture: teamPhotos.teamMember10,
      position: 'Professor at the University of Zagreb',
    },
    {
      footer: <a className="person-footer" href="https://www.fer.unizg.hr/en/antonio.petosic">Research</a>,
      id: 'modal.person.info.antonio-petosic',
      name: 'Antonio Petošić',
      picture: teamPhotos.teamMember11,
      position: 'Associate Professor at the University of Zagreb',
    },
    {
      footer: <a className="person-footer" href="https://www.fer.unizg.hr/en/marko.horvat">Research</a>,
      id: 'modal.person.info.marko-horvat',
      name: 'Marko Horvat',
      picture: teamPhotos.teamMember12,
      position: 'Assistant Professor at the University of Zagreb',
    },
    {
      footer: <a className="person-footer" href="https://www.fer.unizg.hr/en/miljenko.krhen">Research</a>,
      id: 'modal.person.info.miljenko-krhen',
      name: 'Miljenko Krhen',
      picture: teamPhotos.teamMember13,
      position: 'External Associate at the University of Zagreb',
    },
    {
      footer: <span>
        <a className="person-footer" href="https://www.kuleuven.be/wieiswie/en/person/00046505">
            KU Leuven
        </a>
        <br/>
        <a
            className="person-footer"
            href="http://www.tedxbratislava.sk/en/video/monika-rychtarikova-big-bang-virtual-acoustics"
        >
            TEDx Bratislava
        </a>
      </span>,
      id: 'modal.person.info.monika-rychtarikova',
      name: 'Monika Rychtáriková',
      picture: teamPhotos.teamMember14,
      position: 'Professor at KU Leuven',
    },
    {
      id: 'modal.person.info.yannick-sluyts',
      name: 'Yannick Sluyts',
      picture: teamPhotos.teamMember15,
      position: 'MSc Student at KU Leuven',
    },
    {
      footer: <a className="person-footer" href="http://www.hearcandymastering.com/">Hear Candy Mastering</a>,
      id: 'modal.person.info.magdalena-piotrowska',
      name: 'Magdalena Piotrowska',
      picture: teamPhotos.teamMember16,
      position: 'Founder and Head Engineer at TEANO',
    },
    {
      footer: <a className="person-footer" href="http://speedframecrew.com/">Speed Frame Crew</a>,
      id: 'modal.person.info.lech-baranik',
      name: 'Lech Baranik',
      picture: teamPhotos.teamMember17,
      position: 'Filmmaker',
    },
];

class Team extends React.Component {
  togglePersonModal(person) {
    this.props.openModal(modalNames.TEAM_PERSON_VIEW, person);
  }

  render() {
  return (
    <div className="container">
      <div className="row py-5">
        <div className="col-12 pb-5 overflow-hidden">
          <h4 className="section-heading text-center">
            <FormattedMessage id="page.about.team.title" />
          </h4>
        </div>
        {team.map((member) => {
          return (
            <div
              key={member.id}
              className="col-12 col-md-6 col-lg-4 py-4"
              onClick={() => this.togglePersonModal(member)}
            >
              <div className="d-flex flex-md-row flex-column team-member">
                <div className="avatar-wrapper pr-0 pr-md-2 mx-auto mx-md-0">
                  <img
                    className="img-fluid rounded-circle avatar"
                    src={member.picture}
                    alt={member.name}
                  />
                  <span className="icon-arrowR avatar-arrow" />
                </div>
                <div className={
                  `data-wrapper
                  d-flex flex-column justify-content-center
                  pl-0 pl-md-2 pt-4 pt-md-0 mx-auto mx-md-0
                  text-center text-md-left`
                }>
                  <b className="about__team-member">{member.name}</b>
                  <div className="position">{member.position}</div>
                  {
                    member.extraPosition &&
                    <div className="position position--lighten">{member.extraPosition}</div>
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
  }
}

Team.propTypes = {
  openModal: PropTypes.func,
};

export default connect(
  null,
  ({
    openModal: modalActions.open,
  })
)(Team);
