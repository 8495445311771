import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import './SideMenu.scss';

class SideMenu extends React.Component {
  state = {
    open: false,
  };

  toggleMenu = () => {
    this.setState({open: !this.state.open});
  };

  onLinkClick = (link) => {
    this.toggleMenu();
    this.props.push(link);
  };

  render() {
    return (
      <div className={classnames('side-menu', {
        open: this.state.open
      })}>
        <div className="side-menu-header" onClick={this.toggleMenu}>
          <img src={this.props.ico} alt=""/>
          <FormattedMessage id={this.props.title}/>
        </div>
        <div className="side-menu-body">
          {
            this.props.menu.map((group, key) => {
              return (
                <div key={key}>
                  {
                    group.groupName ?
                    <span className="side-menu-item-group">
                      <FormattedMessage id={group.groupName}/>
                    </span> :
                    null
                  }
                  {
                    group.data.map((item, key)=>{
                      return (
                        <div
                          className="side-menu-item"
                          onClick={() => this.onLinkClick(item.link)}
                          key={key}
                        >
                          <FormattedMessage id={item.text}/>
                        </div>
                      );
                    })
                  }
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

SideMenu.propTypes = {
  ico: PropTypes.string,
  title: PropTypes.string,
  menu: PropTypes.array,
  push: PropTypes.func,
};

export default connect(
  null,
  ({ push })
)(SideMenu);
