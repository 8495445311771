import React from 'react';
import propTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import TopBar from '../TopBar/TopBar';
import HomePage from '../../pages/HomePage';
import Footer from '../Footer/Footer';
import AboutPage from '../../pages/About';

import CrossDomainProvider from '../CrossDomain/CrossDomainProvider';
import CrossDomainReceiver from '../CrossDomain/CrossDomainReceiver';

class GlobalLayout extends React.Component {
  render() {
    // const isLogged = false;
    return (
      <CrossDomainProvider>
        <TopBar />
        <div className="page-content">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/about" component={AboutPage} />
          </Switch>
        </div>
        <Footer />
        <Route path="/cross-domain" component={CrossDomainReceiver} />
      </CrossDomainProvider>
    );
  }
}

GlobalLayout.propTypes = {
  children: propTypes.element,
};

export default GlobalLayout;
