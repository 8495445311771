import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import {FormattedHTMLMessage} from 'react-intl';
import QuizService from '../../services/QuizService';
import ls from '../../services/LocalStorageService';
import { quizConfig } from './QuizConfig';
import './Quiz.scss';

const questionsNumber = 20;

class Quiz extends React.Component {
  quizService = new QuizService();

  state = {
    clickedCorrect: false,
    isOpenEndModal: false,
    quizQuestions: this.quizService.shuffle(quizConfig, questionsNumber),
    quizSolved: false,
    quizStage: 1,
    showHint: undefined,
    timeout: undefined,
  };

  UNSAFE_componentWillMount() {
    const newShowHintArray = this.quizService.generateShowHintArray(this.state.quizQuestions, this.state.quizStage);
    const retrievedLocalQuiz = ls.get('quiz');

    if (retrievedLocalQuiz) {
      if (retrievedLocalQuiz.quizSolved) {
        return this.syncWithLS({
          quizSolved: false,
          showHint: newShowHintArray,
        });
      }
      const getState = ls.get('quiz');
      return this.setState({
        ...getState,
        showHint: newShowHintArray,
      });
    }

    return this.syncWithLS({
      showHint: newShowHintArray,
    });
  }

  componentDidUpdate() {
      window.clearTimeout(this.state.timeout);
  }

  is_touch_device() {
    const isTouchDevice = 'ontouchstart' in window
            || navigator.maxTouchPoints;
    return !!isTouchDevice;
  }

  getTimeout() {
    window.setTimeout(() => {
      this.syncWithLS({
        clickedCorrect: false,
        quizStage: this.state.quizStage + 1,
      });
    }, 1500);
  }

  syncWithLS(state) {
    this.setState(state, () => ls.set('quiz', this.state));
  }

  checkAnswer(correct, key) {
    const newShowHintArray = this.quizService.generateShowHintArray(this.state.quizQuestions, this.state.quizStage);

    if (!correct) {
      return this.syncWithLS({
        showHint: {
            [key]: true,
        },
      });
    }

    const last = this.state.quizStage === questionsNumber;
    if (last) {
      return this.syncWithLS({
        clickedCorrect: correct,
        quizSolved: true,
        showHint: newShowHintArray,
      });
    }

    this.syncWithLS({
      clickedCorrect: correct,
      showHint: newShowHintArray,
      timeout: this.getTimeout(),
    });
  }

  renderQuiz() {
    const { clickedCorrect, showHint, quizQuestions, quizStage } = this.state;
    const actualQuestion = quizQuestions[quizStage - 1];
    const disableButton = clickedCorrect;
    const isTouchDevice = this.is_touch_device();

    return actualQuestion.answers.map((item, key) => {
      return (
        <div
          key={key}
          className={classnames('quiz__answer', {
            'is-not-touch-device': !isTouchDevice,
            'is-touch-device': isTouchDevice,
          })}
        >
          <button
            onClick={() => this.checkAnswer(item.correct, key)}
            disabled={disableButton}
            className={classnames('quiz__answer-btn', {
                'answered-correct': clickedCorrect && item.correct,
                'answered-wrong' : showHint[key],
            })}
          >
            {
              (clickedCorrect && item.correct) &&
                  (<span className="quiz-nextq-loader"></span>)
            }
            <div className="quiz-question-point">
                {this.quizService.generateQuizQuestionPoints(quizQuestions[key].answers.length)[key]}
            </div>
            <FormattedHTMLMessage id={item.answerID} />
          </button>
          {
            showHint[key] &&
              (<div className="answer__hint">
                  <FormattedHTMLMessage id="materials.quiz.hint-title"/>
                  <FormattedHTMLMessage id={actualQuestion.hintID}/>
              </div>)
          }
        </div>
      );
    });


  }

  
  render() {
    const { push } = this.props;
    const { quizQuestions, quizStage, quizSolved } = this.state;

    const actualQuestion = quizQuestions[quizStage - 1];

    return (
      <div className="lesson-main quiz-main pt-xl-4 pb-xl-5 py-lg-2 quiz-scroll">
        <div className="interactive col-md-12">
          <h4 className="quiz-title">
            Quiz
          </h4>
          <div className="question__progress">
            <h5>
              Question
              <span className={'question__progress-numbers'}>
                  {` ${quizStage} of ${questionsNumber}`}
              </span>
            </h5>
          </div>
          <h2 className="quiz__actual-question">
            <FormattedHTMLMessage id={actualQuestion.questionID}/>
          </h2>
          <div className="content">
            {this.renderQuiz()}
            {quizSolved &&
              <div className="quiz-solved-btn-box">
                  <div className="btn btn-block btn-secondary"
                        onClick={() => push('/materials/intro')}
                  >
                    <FormattedHTMLMessage id="materials.quiz.finish"/>
                  </div>
              </div>
              }
          </div>
        </div>
      </div>
    );
  }
}

Quiz.propTypes = {
  match: PropTypes.object,
  push: PropTypes.func,
  language: PropTypes.string,
};

export default connect(
  (state) => ({
    language: state.language
  }),
  ({
    push
  })
)(Quiz);
