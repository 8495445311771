import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';

import classnames from 'classnames';

import PropTypes from 'prop-types';

import { logout } from '../../actions/authActions';
import { changeLanguage } from '../../actions/langActions';
import ApiAuth from '../../services/ApiAuth';
import Select from '../Select/Select';
import Lesson from '../Lesson/Lesson';
import LessonMedia from '../LessonMedia/LessonMedia';
import LessonVideo from '../LessonMedia/LessonVideo';
import Quiz from '../Quiz/Quiz';
import MaterialsChapters from '../MaterialsChapters/MaterialsChapters';
import ls from '../../services/LocalStorageService';

import logoACI from '../../assets/svg/aci_short.svg';
import logoAcoucou from '../../assets/svg/logo_acoucou.svg';
import userIcon from '../../assets/svg/user.svg';
import menuIcon from '../../assets/svg/menu.svg';
import arrowIcon from '../../assets/svg/arrow-r.svg';

import { lessonsConfig } from '../Lesson/LessonsConfig';
import { lessonsMediaConfig } from '../LessonMedia/LessonsMediaConfig';

const lessons =  [
  {
    label: <FormattedMessage id="materials.chapter.1.title" />,
    value: 1,
    link: '/materials/lesson/1/1'
  },
  {
    label: <FormattedMessage id="materials.chapter.2.title" />,
    value: 2,
    link: '/materials/lesson/2/1'
  },
  {
    label: <FormattedMessage id="materials.chapter.3.title" />,
    value: 3,
    link: '/materials/lesson/3/1'
  },
  {
    label: <FormattedMessage id="materials.chapter.4.title" />,
    value: 4,
    link: '/materials/lesson/4/1'
  },
  {
    label: <FormattedMessage id="materials.chapter.4.1.title" />,
    value: 5,
    link: '/materials/lesson/5/1'
  },
  {
    label: <FormattedMessage id="materials.chapter.4.2.title" />,
    value: 6,
    link: '/materials/lesson/6/1'
  },
  {
    label: <FormattedMessage id="materials.chapter.4.3.title" />,
    value: 7,
    link: '/materials/lesson/7/1'
  },
  {
    label: <FormattedMessage id="materials.chapter.media.1.title" />,
    value: 8,
    link: '/materials/video/8/1'
  },
  {
    label: <FormattedMessage id="materials.chapter.media.2.title" />,
    value: 9,
    link: '/materials/media/9/1'
  },
  {
    label: <FormattedMessage id="materials.chapter.quiz.1.title" />,
    value: 10,
    link: '/materials/quiz/10'
  },
];

const languages =  [
  {
    label: 'EN',
    value: 'en',
  },
  {
    label: 'PL',
    value: 'pl',
  },
  {
    label: 'HR',
    value: 'hr',
  }
];

class LoggedLayout extends React.Component {

  state = {
    isMobile: false,
    mobileExpanded: false,
    scrolled: window.scrollY > 0,
  };

  componentDidMount() {
    const { loginData, push, needToLogin } = this.props;
    const isLogged = loginData && !!loginData.username;

    window.addEventListener('resize', this.checkWidth);
    this.checkWidth();

    if (needToLogin && !isLogged) {
      push('/');
      return false;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkWidth);
  }

  checkWidth = () => {
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

    return this.setState({
      isMobile: (width < 1024),
    });
  };

  handleToggleMobile = () => {
    this.setState({
      mobileExpanded: !this.state.mobileExpanded,
    });
  };

  handleWindowScroll = () => {
    const scrolled = window.scrollY > 0;

    if (scrolled === this.state.scrolled) {
      return;
    }

    this.setState({ scrolled });
  };

  handleLanguageChange = (value) => {
    ls.set('lang', value);
    this.props.changeLanguage(value);
};

  logout = () => {
    this.props.logout();
    this.props.push('/');
  };

  renderDesktopLinks() {
    return (
      this.props.menuLinks.map((item) => {
        return (
          <li key={item.id} className="nav-item">
            <Link className="nav-link nav-link--small" to={item.to}>
              <FormattedMessage id={item.id} />
            </Link>
          </li>
        );
      })
    );
  }

  getCurrentYear = () => {
    return new Date().getFullYear();
  };

  onSelect = (value) => {
    const selectedValue = lessons.find(item => {
      return item.value === value;
    });
    return this.props.push(selectedValue.link);
  };

  onChangePage = (nextPage, pagesAmount) => {
    const { push } = this.props;
    const { lesson, page } = this.props.match.params;

    if(nextPage <= pagesAmount && nextPage > 0) {
      push(`/materials/lesson/${lesson}/${nextPage}`);
    } else {
      this.inputRef.value = page;
    }
  };

  renderArrowNext = (config, route, lessonNumber, pageNumber) => {
    const { language } = this.props;
    const isNextLesson = config && !!config[pageNumber];
    const isNextChapter = !!lessonsConfig[language][lessonNumber+1];

    if (!isNextLesson && isNextChapter) {
      return (
        <div
          className={classnames('page-arrow-wrapper page-arrow-right chapter-arrow')}
          onClick={() => {
            this.props.push(`/materials/${route}/${lessonNumber+1}/1`);
          }}
        >
          next chapter
          <span className="page-arrow-char-right">{'f'}</span>
        </div>
      );
    }

    return (
      <div
        className={classnames('page-arrow-wrapper page-arrow-right', {
          'page-arrow-wrapper--disabled': !isNextLesson,
        })}
        onClick={() => {
          this.props.push(`/materials/${route}/${lessonNumber}/${pageNumber + 1}`);
        }}
      >
        <span className="page-arrow-char-right">{'f'}</span>
      </div>
    );
  };

  renderArrowNavigation = (page, config, route) => {

    if (!page) {
      return null;
    }
    const { lesson } = this.props.match.params;
    const pageNumber = page ? parseInt(page) : 1;
    const lessonNumber = lesson ? parseInt(lesson) : 1;

    const isPrevLesson = config &&
                          !!config[pageNumber - 2];


    return (
      <React.Fragment>
        <div
          className={classnames('page-arrow-wrapper page-arrow-left', {
            'page-arrow-wrapper--disabled': !isPrevLesson,
          })}
          onClick={() => { this.props.push(`/materials/${route}/${lessonNumber}/${pageNumber - 1}`); }}
        >
          <span className="page-arrow-char-left">{'b '}</span>
        </div>

        {this.renderArrowNext(config, route, lessonNumber, pageNumber)}

      </React.Fragment>
    );
  };

  render() {
    const { loginData, classNames, showBar, language, needToLogin } = this.props;
    const { lesson, page, audioPage } = this.props.match.params;

    const userName = needToLogin ? ApiAuth.getUserName(loginData.username) : null;
    const isLogged = loginData && !!loginData.username;

    if (needToLogin && !isLogged) {
      return null;
    }

    const pageNumber = page ? parseInt(page) : 1;
    const lessonNumber = lesson ? parseInt(lesson) : 1;
    const isPrevLesson = lessonsConfig[language][lessonNumber] &&
                          !!lessonsConfig[language][lessonNumber][pageNumber - 2];
    const isNextLesson = lessonsConfig[language][lessonNumber] && !!lessonsConfig[language][lessonNumber][pageNumber];
    const pagesAmount = lessonsConfig[language][lessonNumber] && lessonsConfig[language][lessonNumber].length;

    needToLogin && ApiAuth.setHeaders();

    return (
      <div>
        <nav id="nav-menu"
          className={classnames('navbar navbar-toggleable-xl fixed-top d-block navbar-light scrolled',
          classNames, {
          'extra-logo-animation': false,
            })}>
          <div className="nav-menu__container hidden-sm-down">
            <Link className="navbar-brand navbar-brand--small" to="/">
              <img src={logoACI} alt="Logo" />
            </Link>
            {showBar && <React.Fragment>

              {this.renderArrowNavigation(
                page ? page : audioPage ? audioPage : null,
                page ? lessonsConfig[language][lessonNumber] : lessonsMediaConfig[language],
                page ? 'lesson' : 'media'
                )
              }

              <Select
                className="select--smaller ml-5"
                options={lessons}
                value={lessonNumber}
                onSelect={(value) => this.onSelect(value)}
              />

              {page &&
                <div className="navbar__pages">
                  <span className="navbar__pages-text pl-3 pr-2"><FormattedMessage id="nav.bar.page" />:</span>
                  <div
                    className={classnames('navbar__arrow-left', {
                      'navbar__arrow-left--disabled': !isPrevLesson,
                    })}
                    onClick={() => {this.props.push(`/materials/lesson/${lessonNumber}/${pageNumber-1}`);}}
                  >
                    <div className="navbar__arrow-left__icon" />
                  </div>
                  <input
                    ref={ref => (this.inputRef = ref)}
                    type ="text"
                    className="navbar__lesson-input"
                    defaultValue={page}
                    key={page}
                    onBlur={e => this.onChangePage(e.target.value, pagesAmount)}
                  />
                  <span className="navbar__lesson-count mr-2">{` /\xa0\xa0` + pagesAmount}</span>
                  <div
                    className={classnames('navbar__arrow-right', {
                      'navbar__arrow-right--disabled': !isNextLesson,
                    })}
                    onClick={() => {this.props.push(`/materials/lesson/${lessonNumber}/${pageNumber+1}`);}}
                  >
                    <div className="navbar__arrow-right__icon" />
                  </div>
                </div>
              }

            </React.Fragment>}
            <ul className="navbar-nav ml-auto">
              {this.renderDesktopLinks()}
              {needToLogin &&
                <React.Fragment>
                  <li
                    className="nav-item"
                  // onClick={() => browserHistory.push('/user/profile')}
                  >
                    <div className="nav-link nav-link--small with-no-padding-right">
                      <span className="navbar__username">
                        <strong>{userName}</strong>&nbsp;|&nbsp;
                  </span>
                    </div>
                  </li>
                  <li
                    className="nav-item"
                    onClick={this.logout}
                  >
                    <div className="nav-link nav-link--small with-no-padding-left with-extra-content">
                      <FormattedMessage id="menu.logout" />
                      <img src={userIcon} className="nav-icon" />
                    </div>
                  </li>
                </React.Fragment>}
            </ul>
            <Select
              className="select select--small ml-2"
              options={languages}
              value={language}
              onSelect={(value) => this.handleLanguageChange(value)}
            />
          </div>
          <div className="container d-block mx-auto hidden-md-up">
            <div className="row d-block">
              <div className={classnames('navbar-mobile', {
                expand: this.state.mobileExpanded,
              })}>
                <div className="navbar-left">
                  <span className="ai ai-user" onClick={() => this.handleToggleModal('login')} />
                </div>
                <div className="navbar-center">
                  <img src={logoACI} alt="Logo" />
                </div>
                <div className="navbar-right" onClick={this.handleToggleMobile}>
                  <img src={menuIcon} className="nav-icon nav-icon--big" />
                </div>
              </div>
              <div className="navbar-mobile-overlay" onClick={this.handleToggleMobile} />
                <ul className="navbar-nav text-right" onClick={this.handleToggleMobile}>
                  <li className="nav-item">
                    <img src={arrowIcon} className="nav-icon" />
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link nav-link--small ai ai-signet text-primary" to="/" />
                  </li>
                  {this.props.menuLinks.map((item) => {
                    return <li
                      className={classnames('nav-item', {
                      // active: router.isActive(item.to, true),
                      })}
                        key={item.id}
                      >
                        <Link className="nav-link nav-link--small" to={item.to} onClick={() => {
                          // scroll to products on products element
                          if (item.to !== '/') {
                              return;
                          }
                        }} >
                          <FormattedMessage id={item.id} />
                        </Link>
                    </li>;
                  })}
                  {needToLogin && <li
                    className="nav-item"
                    onClick={this.logout}
                  >
                    <div className="nav-link nav-link--small">
                      <FormattedMessage id="menu.logout" />
                    </div>
                  </li>}
                </ul>
              </div>
            </div>
        </nav>
        <div className="page-content">
          <Switch>
            <Route exact path="/materials/lesson/:lesson/:page" component={Lesson} />
            <Route exact path="/materials/media/:lesson/:audioPage" component={LessonMedia} />
            <Route exact path="/materials/video/:lesson/:videoPage" component={LessonVideo} />
            <Route exact path="/materials/quiz/:lesson" component={Quiz} />
            <Route exact path="/materials/intro" component={MaterialsChapters} />
          </Switch>
        </div>
        <footer className="footer footer--light footer--fixed">
          <div className="container-fluid footer__container">
            <div className="row">
              <div className="col-12 py-2 text-center copyright justify-content-center">
                <img src={logoAcoucou} alt="ACE" className="img-fluid footer__logo" />
                <FormattedMessage id="footer.copyright" />
                <span>&nbsp;{this.getCurrentYear()}.&nbsp;</span>
                <span>
                  <FormattedMessage id="footer.copyright2" />
                  [<a
                    className="copyright-link"
                    href="https://creativecommons.org/licenses/by-nc-nd/4.0/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CC BY-NC-ND 4.0
                  </a>]
                </span>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

LoggedLayout.propTypes = {
  changeLanguage: PropTypes.func,
  language: PropTypes.string,
  match: PropTypes.object,
  children: PropTypes.element,
  logout: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  loginData: PropTypes.object,
  needToLogin: PropTypes.bool,
  showBar: PropTypes.bool,
  menuLinks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  })).isRequired,
};

export default withRouter(connect(
    (state) => ({
      loginData: state.login.data,
      language: state.language
    }),
    ({
      push, logout, changeLanguage
    }),
)(LoggedLayout));
