import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import classnames from 'classnames';
import vrIco from '../../assets/svg/vr_ico.svg';
import audioIco from '../../assets/svg/audio_ico.svg';
import vrIcoHover from '../../assets/svg/vr_ico_white.svg';
import audioIcoHover from '../../assets/svg/audio_ico_white.svg';
import ChaptersInfo from './ChapterInfo/ChaptersInfo';
import logoErasmus from '../../assets/img/erasmus_logo.png';

import './MaterialsChapters.scss';

const chaptersInfo = {
  1: <ChaptersInfo
        title="materials.chapter.1.title"
        info={[{type: 'text', data: 'materials.chapter.1.info.p1'}]}
        buttons={
          [{
            title: 'materials.chapter.1.btn1',
            link: '/materials/lesson/1/1'
          },{
            title: 'materials.chapter.1.btn2',
            link: '/materials/lesson/1/2'
          },{
            title: 'materials.chapter.1.btn3',
            link: '/materials/lesson/1/3'
          },{
            title: 'materials.chapter.1.btn4',
            link: '/materials/lesson/1/4'
          },{
            title: 'materials.chapter.1.btn5',
            link: '/materials/lesson/1/5'
          }]}
      />,
  2: <ChaptersInfo
        title="materials.chapter.2.title"
        info={[{type: 'text', data: 'materials.chapter.2.info.p1'}]}
        buttons={
          [{
            title: 'materials.chapter.2.btn1',
            link: '/materials/lesson/2/1'
          },{
            title: 'materials.chapter.2.btn2',
            link: '/materials/lesson/2/2'
          },{
            title: 'materials.chapter.2.btn3',
            link: '/materials/lesson/2/3'
          },{
            title: 'materials.chapter.2.btn4',
            link: '/materials/lesson/2/5'
          },{
            title: 'materials.chapter.2.btn5',
            link: '/materials/lesson/2/4'
          },{
            title: 'materials.chapter.2.btn6',
            link: '/materials/lesson/2/6'
          },{
            title: 'materials.chapter.2.btn7',
            link: '/materials/lesson/2/7'
          },{
            title: 'materials.chapter.2.btn8',
            link: '/materials/lesson/2/9'
          },{
            title: 'materials.chapter.2.btn9',
            link: '/materials/lesson/2/8'
          },{
            title: 'materials.chapter.2.btn10',
            link: '/materials/lesson/2/12'
          },{
            title: 'materials.chapter.2.btn11',
            link: '/materials/lesson/2/13'
          },{
            title: 'materials.chapter.2.btn12',
            link: '/materials/lesson/2/14'
          },{
            title: 'materials.chapter.2.btn13',
            link: '/materials/lesson/2/15'
          }, {
            title: 'materials.chapter.2.btn14',
            link: '/materials/lesson/2/30'
          }]}
    />,
    3: <ChaptersInfo
        title="materials.chapter.3.title"
        info={[{type: 'text', data: 'materials.chapter.3.info.p1'}]}
        buttons={
          [{
            title: 'materials.chapter.3.btn1',
            link: '/materials/lesson/3/1'
          },{
            title: 'materials.chapter.3.btn2',
            link: '/materials/lesson/3/2'
          },{
            title: 'materials.chapter.3.btn3',
            link: '/materials/lesson/3/3'
          },{
            title: 'materials.chapter.3.btn4',
            link: '/materials/lesson/3/4'
          },{
            title: 'materials.chapter.3.btn5',
            link: '/materials/lesson/3/5'
          },{
            title: 'materials.chapter.3.btn6',
            link: '/materials/lesson/3/6'
          },{
            title: 'materials.chapter.3.btn7',
            link: '/materials/lesson/3/7'
          },{
            title: 'materials.chapter.3.btn8',
            link: '/materials/lesson/3/8'
          },{
            title: 'materials.chapter.3.btn9',
            link: '/materials/lesson/3/9'
          },{
            title: 'materials.chapter.3.btn10',
            link: '/materials/lesson/3/10'
          },{
            title: 'materials.chapter.3.btn11',
            link: '/materials/lesson/3/11'
          },{
            title: 'materials.chapter.3.btn12',
            link: '/materials/lesson/3/12'
          },{
            title: 'materials.chapter.3.btn13',
            link: '/materials/lesson/3/13'
          }]}
      />,
    4: <ChaptersInfo
      title="materials.chapter.4.title"
      info={[{type: 'text', data: 'materials.chapter.4.info.p1'}]}
      buttons={
        [{
          title: 'materials.chapter.4.btn1',
          link: '/materials/lesson/4/1'
        },{
          title: 'materials.chapter.4.btn2',
          link: '/materials/lesson/4/2'
        },{
          title: 'materials.chapter.4.btn3',
          link: '/materials/lesson/4/3'
        },{
          title: 'materials.chapter.4.btn4',
          link: '/materials/lesson/4/4'
        },{
          title: 'materials.chapter.4.btn5',
          link: '/materials/lesson/4/5'
        },{
          title: 'materials.chapter.4.btn6',
          link: '/materials/lesson/4/6'
        }]}
    />,
    5: <ChaptersInfo
      title="materials.chapter.4.1.title"
      info={[{type: 'text', data: 'materials.chapter.4.1.info.p1'}]}
      buttons={[
        {
          title: 'materials.chapter.4.1.btn1',
          link: '/materials/lesson/5/1'
        }
      ]}
    />,
    6: <ChaptersInfo
      title="materials.chapter.4.2.title"
      info={[{type: 'text', data: 'materials.chapter.4.2.info.p1'}]}
      buttons={[
        {
          title: 'materials.chapter.4.2.btn1',
          link: '/materials/lesson/6/1'
        }
      ]}
    />,
    7: <ChaptersInfo
      title="materials.chapter.4.3.title"
      info={[{type: 'text', data: 'materials.chapter.4.3.info.p1'}]}
      buttons={[
        {
          title: 'materials.chapter.4.3.btn1',
          link: '/materials/lesson/7/1'
        }
      ]}
    />,
    8: <ChaptersInfo
      title="materials.chapter.media.1.title"
      info={[{type: 'text', data: 'materials.chapter.media.1.info.p1'}]}
      buttons={[
        {
          title: 'materials.chapter.media.1.btn1',
          link: '/materials/video/8/1'
        }
      ]}
    />,
    9: <ChaptersInfo
      title="materials.chapter.media.2.title"
      info={[{type: 'text', data: 'materials.chapter.media.2.info.p1'}]}
      buttons={[
        {
          title: 'materials.chapter.media.2.btn1',
          link: '/materials/media/9/1'
        }
      ]}
    />,
    10: <ChaptersInfo
      title="materials.chapter.quiz.1.title"
      info={[{type: 'text', data: 'materials.chapter.quiz.1.info.p1'}]}
      buttons={[
        {
          title: 'materials.chapter.quiz.1.btn1',
          link: '/materials/quiz/10'
        }
      ]}
    />,
};

const chapters = [
  {
    id: 1,
    lessonNumber: 'I',
    text: 'materials.chapter.1.title'
  },
  {
    id: 2,
    lessonNumber: 'II',
    text: 'materials.chapter.2.title'
  },
  {
    id: 3,
    lessonNumber: 'III',
    text: 'materials.chapter.3.title'
  },
  {
    id: 4,
    lessonNumber: 'IV',
    text: 'materials.chapter.4.title'
  },
  {
    id: 5,
    lessonNumber: '1',
    text: 'materials.chapter.4.1.title',
    className: 'submenu'
  },
  {
    id: 6,
    lessonNumber: '2',
    text: 'materials.chapter.4.2.title',
    className: 'submenu'
  },
  {
    id: 7,
    lessonNumber: '3',
    text: 'materials.chapter.4.3.title',
    className: 'submenu'
  }
];

const media = [
  {
    id: 8,
    lessonNumber: 'A',
    text: 'materials.chapter.media.1.title',
    ico: vrIco,
    icoHover: vrIcoHover
  },
  {
    id: 9,
    lessonNumber: 'B',
    text: 'materials.chapter.media.2.title',
    ico: audioIco,
    icoHover: audioIcoHover
  }
];

const quiz = [
  {
    id: 10,
    lessonNumber: 'Q',
    text: 'materials.chapter.quiz.1.title'
  },
];


class MaterialsChapters extends React.Component {
  state = {
    itemId: null,
  };

  setCssChapterVar(val) {
    if (!this.sectionChapters) {
      return null;
    }

    return this.sectionChapters.style.setProperty('--chapter', val);
  }

  renderIcon(ico, icoHover) {
    if(!ico) {
      return null;
    }

    return (
      <span className="list-ico">
        <img className="list-ico-img" src={ico} alt=""/>
        <img className="list-ico-img-hover" src={icoHover} alt=""/>
      </span>
    );
  }

  renderSingleItem(items) {
    if (!items) {
      return null;
    }

    return items.map((item) => {
      const itemId = item.id;

      return (
        <li
          className={classnames('materials-chapters__list-item', item.className, {
            'materials-chapters__list-item--active': itemId === this.state.itemId,
          })}
          key={itemId}
          onClick={() => this.setState({ itemId })}
        >
          <span className="materials-chapters__list-number">{item.lessonNumber}</span>
          <FormattedMessage id={item.text} />
          {this.renderIcon(item.ico, item.icoHover)}
        </li>
      );
    });
  }

  renderChapters() {
    return (
      <React.Fragment>
        <h4 className="materials-chapters__title">
          <FormattedMessage id="materials.chapters" />
        </h4>
        <div
          className={classnames('materials-chapters__list-link', {
            'materials-chapters__list-link--active': this.state.itemId === null,
          })}
          onClick={() => this.setState({ itemId : null })}
        >
          <p className="materials-chapters__chapter-title">
            <span className="materials-chapters__chapter-title-span">A. </span>
            <FormattedMessage id="materials.chapter.welcome.page" />
          </p>
        </div>
        <ol className="materials-chapters__list materials-chapters__list--chapters">
          {this.renderSingleItem(chapters)}
        </ol>
      </React.Fragment>
    );
  }

  renderNavigation(header, data) {
    return (
      <React.Fragment>
        <h4 className="materials-chapters__title">
          <FormattedMessage id={header} />
        </h4>
        <ol className="materials-chapters__list materials-chapters__list--chapters">
          {this.renderSingleItem(data)}
        </ol>
      </React.Fragment>
    );
  }

  renderInfo() {
    if (!this.state.itemId) {
      return (
        <ChaptersInfo
          title="materials.chapter.welcome"
          info={[
            {type: 'text', data: 'materials.chapter.welcome.p1'},
            {type: 'img', data: logoErasmus},
            {type: 'text', data: 'materials.chapter.welcome.p2'}]}
          buttons={[{
            title: 'materials.chapter.begin',
            link: '/materials/lesson/1/1'
          }]}
        />
      );
    }
    return (chaptersInfo)[this.state.itemId];
  }

  render() {
    return (
      <section
        className="materials-chapters"
        ref={(section) => { this.sectionChapters = section; }}
      >
        <div className="row no-gutters materials-chapters__content">
          <div className="col-lg-4 col-xl-3 materials-chapters__chapters-column">
            <div className="materials-chapters__chapters">
              {this.renderChapters()}
              {this.renderNavigation('materials.media', media)}
              {this.renderNavigation('materials.quiz', quiz)}
            </div>
          </div>
          <div className="col-lg-8 col-xl-9 materials-chapters__info-wrapper">
            <div className="materials-chapters__info">
              {this.renderInfo()}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

MaterialsChapters.propTypes = {
  stats: PropTypes.object,
  materials: PropTypes.array,
  push: PropTypes.func,
};

export default connect(
  null,
  ({ push })
)(MaterialsChapters);
