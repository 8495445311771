import { CHANGE_LANGUAGE } from './types';

export const changeLanguage = (value) => ({
  type: CHANGE_LANGUAGE,
  payload: value
});

export default {
  changeLanguage,
};
