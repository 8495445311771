import React from 'react';

import LoggedLayout from './LoggedLayout';

class LoggedLayoutLesson extends React.Component {
  render() {
    const menuLinks = [
      { id: 'menu.goto.fulllist', to: '/materials/intro' },
      { id: 'menu.goto.aci', to: '/' },
    ];

    return <LoggedLayout showBar menuLinks={menuLinks} needToLogin={false} />;
  }
}

export default LoggedLayoutLesson;
