import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import modal from './modal';
import mail from './mail';
import login from './login';
import register from './register';
import resetPassword from './resetPassword';
import language from './language';

export default (history) => combineReducers({
  router: connectRouter(history),

  mail,
  modal,
  login,
  register,
  resetPassword,
  language
});
